import React from 'react';
import { Paper, Typography } from '@mui/material';

const OverViewCard = ({ total }) => {
  return (
    <>
      {/* <DetailsContainer> */}
      <Paper elevation={0}>
        <Typography
          style={{ color: '#2057C0' }}
          component="h6"
          variant="h5"
          align="center"
          color="textPrimary"
        >
          Total: {total}
        </Typography>
      </Paper>
      {/* </DetailsContainer> */}
    </>
  );
};

export default OverViewCard;
