import React, { useState, useEffect } from "react"
import { makeStyles } from "@mui/styles"
import Card from "@mui/material/Card"
import CardMedia from "@mui/material/CardMedia"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import FavoriteIcon from "@mui/icons-material/Favorite"
import CardActionArea from "@mui/material/CardActionArea"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import { Link } from "react-router-dom"
import CardActions from "@mui/material/CardActions"
import { auth, db } from "../Firebase/Firebase"
import create from 'zustand'


const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    height: '100%',
  },
  media: {
    height: 200,
  },
  favIcon: {
    marginLeft: '90%',
  },
  link: {
    textDecoration: 'none'
  }
})

let userUID = '';
let projectId = '';
let docId = '';

const useStore = create(set => ({

  setFavoriteState: () => set(state => ({
    favoriteState: !state.favoriteState
  }))
}))

export default function ProjectsCard(props) {
  const classes = useStyles()
  const [contributedState, setContributedState] = useState(false)
  const refFavotiteProjects = db.collection("favourite_projects")
  const setFavoriteState = useStore(state => state.setFavoriteState)

  async function isUserAlreadyLikeProject(userUID, projectId) {
    const favProjects = await db
      .collection("favourite_projects")
      .where("user_id", "==", userUID)
      .where("project_id", "==", projectId)
      .get()

    if (favProjects.docs.length > 0) {
      docId = favProjects.docs[0].id
      setContributedState(favProjects.docs[0].data().contributed)
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    setFavoriteState(props.isFavourite)
    userUID = auth().currentUser.uid
  })

  async function handleFavorite(event) {
    event.stopPropagation()
    event.preventDefault()

    projectId = props.project_data["project_id"]

    if (await isUserAlreadyLikeProject(userUID, projectId)) {
      if (contributedState) {
        refFavotiteProjects.doc(docId).update({
          favourite: !props.isFavourite,
        })
      } else {
        refFavotiteProjects
          .doc(docId)
          .delete()
          .then(function () {
            console.log("Document successfully deleted!")
          })
          .catch(function (error) {
            console.error("Error removing document: ", error)
          })
      }
    } else {
      refFavotiteProjects
        .add({
          contributed: contributedState,
          favourite: true,
          project_id: projectId,
          user_id: userUID,
        })
        .then(function (docRef) {
        })
        .catch(function (error) {
          console.error("Error adding document: ", error)
        })
    }
    props.handleLike()
  }

  return (
    <Link to={"/details?id=" + props.project_data["project_id"]}>
      <Card className={classes.root} >
        <CardActionArea >
          <CardMedia
            className={classes.media}
            image={props.img}
            title={props.name}
          />
          <CardContent>
          <Typography variant="h5" color="#000" component="p">
              {props.name}
            </Typography>
            <Typography variant="h6" color="#000" component="p" >
              {props.location}
            </Typography>   
            {/* <Typography variant="body2" color="textSecondary" component="p">
              {props.description.substr(0, 50)}
            </Typography> */}
          </CardContent>
        </CardActionArea>
        <CardActions disableSpacing>
          <Grid container
            direction="row"
            justifyContent="flex-end"
            alignitems="flex-end" >
            <IconButton
              onClick={handleFavorite}
              style={{ color: props.isFavourite === true ? "red" : "#ddd" }}>
              <FavoriteIcon />
            </IconButton>
          </Grid>

        </CardActions>
      </Card>
    </Link>
  )
}
