import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import firebase from '../../../Firebase/Firebase'
import { Grid, Box, Modal, Button, Typography, TextField, Divider } from '@mui/material';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import MainForm from '../../../modules/views/MainForm';
import Input from '../../../components/UI/Forms/Input/Input';
import Message from '../../../components/UI/Message/Message';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
import * as actions from '../../../store/actions'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email.')
    .required('The email is required.'),
  password: Yup.string()
    .required('The password is required.')
    .min(5, 'Too short.'),
});

const MessageWrapper = styled.div`
position: absolute;
bottom: -2rem;
`;

const LoginModal = ({ getSignInCredentials, loading, error, _params, openLoginCard, handleCloseLoginCard }) => {

  const useStyles = makeStyles((theme) => ({
    form: {
      marginTop: theme.spacing(1),
    },
    field: {
      '& label.Mui-focused': {
        color: '#0133C0',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#0133C0',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#0133C0',
        },
        '&:hover fieldset': {
          borderColor: '#0133C0',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#0133C0',
        },
      },
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      backgroundColor: "#0133C0",
      color: '#FFF',
      "&:hover": {
        backgroundColor: "#214DC5"
      },
    },
    feedback: {
      marginTop: theme.spacing(2),
    },
    link: {
      color: "#2859C5",
      textDecoration: "none"
    },
    facebookicon: {
      color: '#4267B2'
    },
    googleicon: {
      color: '#d34836'
    }
  }));

  const classes = useStyles();
  const history = useNavigate();


  const signInWithGoogle = () => {
    firebase
      .login({
        provider: "google",
        type: "popup",
      })
      .then(() => {
        history("/Home");
      });
  };


  const signInWithFacebook = () => {
    firebase
      .login({

      })
      .then((data) => {
        alert(JSON.stringify(data));
        // history.push("/home");
      });
  };


  const [userName, setUserName] = useState('')
  const [userEmail, setUserEmail] = useState('')

  if (userName !== '' && userEmail !== '') {
    _params["user_name"] = userName
    _params["user_email"] = userEmail
  }


  return (
    <div>
      <Modal
        open={openLoginCard}
        onClose={handleCloseLoginCard}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '30%',
          p: 1,
          justifyContent: 'center',
        }}>

          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={LoginSchema}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                let res = await getSignInCredentials(values);
                setUserName(res?.user?.displayName);
                setUserEmail(res?.user?.email);
                setSubmitting(false);
                handleCloseLoginCard()
              } catch (error) {
                alert(error.message)
              }
            }}
          >
            {({ isSubmitting, isValid }) => (
              <MainForm>
                <React.Fragment>
                  <Typography variant="h5" gutterBottom marked="center" align="center" style={{ textTransform: "capitalize" }}>
                    Sign In
                  </Typography>
                </React.Fragment>
                <Form className={classes.form}>

                  <Field name='email' type="email" component={Input}>
                    {({ field, form, meta }) => (
                      <TextField
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        id='email'
                        label='Email Address'
                        name='email'
                        autoComplete='email'
                        autoFocus
                        inputProps={{ ...field }}
                      />
                    )}
                  </Field>

                  <Field name='password' type='password' component={Input}>
                    {({ field, form, meta }) => (
                      <TextField
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        name='password'
                        label='Password'
                        type='password'
                        id='password'
                        autoComplete='current-password'
                        inputProps={{ ...field }}
                      />
                    )}
                  </Field>
                  <div style={{ padding: '10px' }} />
                  <Button
                    disabled={!isValid || isSubmitting}
                    loading={loading ? 'Logging in...' : null}
                    type="submit"
                    style={{ color: '#fff', backgroundColor: '#2057C0', width: '100%' }}
                  >
                    Login
                  </Button>
                  <div style={{ padding: '10px' }} />
                  <MessageWrapper>
                    <Message error show={error}>
                      {error}
                    </Message>
                  </MessageWrapper>
                  <div style={{ padding: '15px' }} />
                  <Grid
                    container
                    alignitems="center"
                    justifyContent={"center"}
                    spacing={3}>
                    <Grid item lg={3}><Divider /></Grid>
                    <Grid ><Typography textAlign="center">OR</Typography></Grid>
                    <Grid item lg={3}><Divider /></Grid>
                  </Grid>

                  <div style={{ padding: '15px' }} />
                  <Grid container justifyContent={"center"}>
                    <Grid item >
                      <div
                        type="button"
                        fullWidth
                        variant="contained"
                        onClick={(event) => {
                          event.preventDefault();
                          signInWithFacebook();
                        }}
                        style={{ color: '#4267B2' }}>
                        <FontAwesomeIcon icon={faFacebook} size="2x" />
                      </div>
                    </Grid>

                    <Grid item>
                      <div
                        type="button"
                        fullWidth
                        variant="contained"
                        onClick={(event) => {
                          event.preventDefault();
                          signInWithGoogle();
                        }}
                        style={{ color: '#DB4437' }}>
                        <FontAwesomeIcon icon={faGoogle} size="2x" />
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              </MainForm>
            )}
          </Formik>
        </Box>
      </Modal>
    </div >
  )
}
// export default LoginModal

const mapStateToProps = ({ auth }) => ({
  loading: auth.loading,
  error: auth.error,
});

const mapDispatchToProps = {
  getSignInCredentials: actions.getSignInCredentials,
  cleanUp: actions.clean,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginModal);



