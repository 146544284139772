import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  root: {
    overflow: "hidden",
    [theme.breakpoints.only("xl")]: {
      marginTop: "45px",
    },
    [theme.breakpoints.only("xs")]: {
      marginTop: "40px",
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: "35px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      marginTop: "30px",
    },
  },
  images: {
    marginTop: theme.spacing(2),
    align: "center",
    [theme.breakpoints.down("xs")]: {
      align: "center",
    },
  },

  content: {
    marginTop: "-40px",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignitems: "center",
    padding: theme.spacing(0, 3),
  },
  p2: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  paper: {
    marginTop: "-80px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  about: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  container: {
    overflow: "hidden",
    backgroundColor: "#FFF",
    marginTop: "40px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "50px",
    },
  },
  text: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "justify",
    },
  },
  shapeColor: {
    backgroundColor: "#2057C0",
    borderRadius: "50px",
    borderTopWidth: 2,
    borderBottomWidth: 2,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderColor: "grey",
    borderStyle: "solid",
  },
});

function JoiningProgram(props) {
  const { classes } = props;

  return (
    <div
      className={`wow animate__slow animate__animated animate__fadeInLeft ${classes.root}`}
    >
      <Grid container justifyContent={"center"}>
        <Grid
          item
          xs={10}
          sm={10}
          md={10}
          lg={10}
          xl={10}
          align="left"
          style={{
            padding: "2.5rem",
            paddingTop: "1.7rem",
            backgroundColor: "#fff",
            color: "#000",
            boxShadow: "rgb(206 206 206) 0px 0px 10px",
            borderRadius: "10px",
            fontFamily: "Poppins",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <br />
          <Typography
            variant="h2"
            style={{
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "32px",
            }}
          >
            Are you a charity and interested in joining our program?
          </Typography>
          <Typography
            style={{
              fontStyle: "normal",
              fontSize: "16px",
              marginTop: "20px",
              fontFamily: "Poppins",
              color: "#000",
            }}
          >
            Send us a <Link
              to="/contact-us">message </Link>  and we will reach out to you.

          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

JoiningProgram.propTypes = { classes: PropTypes.object.isRequired };

export default withStyles(styles)(JoiningProgram);
