import React from 'react'
import History from '../causes/History'
import Navbar from '../Navbar';


function Transactions() {
    return (
        <React.Fragment>
            <Navbar />
            <History />
        </React.Fragment>
    )
}

export default Transactions;