import React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '140%',
    backgroundColor: '#2057C0',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: '#fff',
    borderStyle: 'solid',

    marginBottom: 20,
  },
  media: {
    height: 200,
  },

  link: {
    textDecoration: 'none',
  },
});

export default function ProjectsCard(props) {
  const classes = useStyles();

  return (
    <Link to={'/details?id=' + props.project_data['project_id']}>
      <Card
        spacing={0}
        direction="column"
        alignitems="center"
        justifyContent={"center"}
        style={{
          backgroundColor: '#2057C0',
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderLeftWidth: 1,
          borderRightWidth: 1,
          borderColor: '#fff',
          borderStyle: 'solid',
          marginBottom: 20,
        }}
      >
        <CardActionArea>
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              component="h3"
              style={{ color: '#fff' }}
            >
              Project in {props.country}
            </Typography>
          </CardContent>
          <CardMedia
            className={classes.media}
            image={props.img}
            title={props.name}
          />
        </CardActionArea>
      </Card>
    </Link>
  );
}
