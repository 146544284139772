import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';

const styles = (theme) => ({
  root: {
    display: 'flex',
    // overflow: "hidden",
  },
  paper: {
    padding: theme.spacing(4, 3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6, 4),
    },
  },
});

function FormStyles(props) {
  const { children, classes } = props;

  return (
    <div style={{
      display: 'flex',
      padding: '0px',
      margin: '0px',
      overflow: 'hidden',
      // height: '100vh',
      // width: '100vw',
      maxWidth: '600px !important',
      justifyContent: 'center',
      alignItems: 'center',

    
    }}>
        <Box>
          <Paper className={classes.paper} elevation={0}>{children}</Paper>
        </Box>
    </div>
  );
}

FormStyles.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FormStyles);
