import React from "react";
import { Divider, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  line: {
    backgroundColor: "#2057c0",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

function Line() {
  const classes = useStyles();
  return (
    <Grid container alignitems="center" justifyContent={"center"}>
      <Grid item lg={11}>
        <Divider className={classes.line} />
      </Grid>
    </Grid>
  );
}

export default Line;
