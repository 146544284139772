import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import { db } from "../../Firebase/Firebase";
import Card from "../../pages/CardAbout";

const styles = (theme) => ({
  root: {
    overflow: "hidden",
    [theme.breakpoints.only("xl")]: {
      marginTop: "45px",
    },
    [theme.breakpoints.only("xs")]: {
      marginTop: "40px",
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: "35px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      marginTop: "30px",
    },
  },
  images: {
    marginTop: theme.spacing(2),
    align: "center",
    [theme.breakpoints.down("xs")]: {
      align: "center",
    },
  },

  content: {
    marginTop: "-40px",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 3),
  },
  p2: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  paper: {
    marginTop: "-80px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  about: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  container: {
    overflow: "hidden",
    backgroundColor: "#FFF",
    marginTop: "40px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "50px",
    },
  },
  text: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "justify",
    },
  },
  shapeColor: {
    backgroundColor: "#2057C0",
    borderRadius: "50px",
    borderTopWidth: 2,
    borderBottomWidth: 2,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderColor: "grey",
    borderStyle: "solid",
  },
});

function DiscoverProjects(props) {
  const { classes } = props;

  const refProjects = db.collection("projects");
  const [projects, setProjects] = useState([]);
  const [completedprojects, setCompletedProjects] = useState([]);

  async function getProjects() {
    refProjects
      .get().then(async function (querySnapshot) {
        const items = [];
        for (var i = 0; i < querySnapshot.docs.length; i++) {
          if (querySnapshot.docs[i].data()["project_status"] === false || querySnapshot.docs[i].data()["project_status"] === 0 || querySnapshot.docs[i].data()["project_status"] === undefined) {
            const projectData = querySnapshot.docs[i].data();
            projectData["project_id"] = querySnapshot.docs[i].id;
            items.push(projectData);
          }
        }
        setProjects(items);
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }

  async function getProjectsCompleted() {
    refProjects
      .get().then(async function (querySnapshot) {
        const items = [];
        for (var i = 0; i < querySnapshot.docs.length; i++) {
          if (querySnapshot.docs[i].data()["project_status"] === true || querySnapshot.docs[i].data()["project_status"] === 1) {
            const projectData = querySnapshot.docs[i].data();
            projectData["project_id"] = querySnapshot.docs[i].id;
            items.push(projectData);
          }
        }

        setCompletedProjects(items);
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }

  useEffect(() => {
    getProjects();
    getProjectsCompleted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const projectComponent = (projects) => {
    return projects.map((project, index) => (
      <Grid className="wow animate__slower animate__animated animate__bounceIn" xs={12} sm={12} md={5} item key={index} >
        {
          project["project_assets"]["image"][0] ?
            <Card
              key={index}
              id={index}
              img={project["project_assets"]["image"][0]}
              country={project["project_location"]["country"]}
              cause={project["cause_display_name"]}
              name={project["project_charity"]}
              description={project["project_description"]}
              project_data={project}
            />
            :
            <Card
              key={index}
              id={index}
              img={project["project_assets"]["image"]}
              country={project["project_location"]["country"]}
              cause={project["cause_display_name"]}
              name={project["project_charity"]}
              description={project["project_description"]}
              project_data={project}
            />
        }
      </Grid >
    ));
  };


  const projectCompleted = (projects) => {
    return projects.map((project, index) => (
      <Grid className="wow animate__slower animate__animated animate__bounceIn" xs={12} sm={12} md={5} item key={index}>
        <Card
          key={index}
          id={index}
          img={project["project_assets"]["image"]}
          country={project["project_location"]["country"]}
          cause={project["cause_display_name"]}
          name={project["project_charity"]}
          description={project["project_description"]}
          project_data={project}
        />
      </Grid >
    ));
  };


  return (
    <>
      <div className={`wow animate__slow animate__animated animate__fadeInLeft ${classes.root}`}>
        <Grid container justifyContent={"center"}>
          <Grid
            item
            xs={10}
            sm={10}
            md={10}
            lg={10}
            xl={10}
            align="left"
            style={{
              padding: "2.5rem",
              paddingTop: "1.7rem",
              backgroundColor: "#fff",
              color: "#000",
              boxShadow: "rgb(206 206 206) 0px 0px 10px",
              borderRadius: "10px",
              fontFamily: "Poppins",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <br />
            <Typography
              style={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "150%",
                textAlign: "center",
                color: "#000",
              }}
            >
              SUPPORT OUR NEW PROJECTS
            </Typography>
            <br />
            <Grid
              item
              align="center"
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              {projectComponent(projects)}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={`wow animate__slow animate__animated animate__fadeInLeft ${classes.root}`}>
        <Grid container justifyContent={"center"}>
          <Grid
            item
            xs={10}
            sm={10}
            md={10}
            lg={10}
            xl={10}
            align="left"
            style={{
              padding: "2.5rem",
              paddingTop: "1.7rem",
              backgroundColor: "#fff",
              color: "#000",
              boxShadow: "rgb(206 206 206) 0px 0px 10px",
              borderRadius: "10px",
              fontFamily: "Poppins",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <br />
            <Typography
              style={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "150%",
                textAlign: "center",
                color: "#000",
              }}
            >
              RECENTLY COMPLETED PROJECTS
            </Typography>
            <br />
            <Grid
              item
              align="center"
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              {projectCompleted(completedprojects)}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

DiscoverProjects.propTypes = { classes: PropTypes.object.isRequired };

export default withStyles(styles)(DiscoverProjects);
