import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { cloudFunctions, auth, database } from '../../../../Firebase/Firebase';
import SimpleBackdrop from './SimpleBackdrop';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
export default function DialogCrypto(props) {
  const [open, setOpen] = useState(false);
  const [setstripeFees] = useState([]);
  let history = useNavigate();

  useEffect(() => {
    getStripeFees();
    getDataList();
    let generate_id = Math.floor(Math.random() * 100000000000);
    localStorage.setItem('unique', generate_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataList = () => {
    database.ref('crypto_transactions/').on('value', function (snapshot) {
      snapshot.forEach((doc) => {
        if (
          doc.val().unique_id === localStorage.getItem('unique') &&
          doc.val().user_name === auth().currentUser.displayName
        ) {
          history({
            pathname: '/thanks',
            state: {
              project_description: props.projectDescription,
              total_amount_donated:
                parseFloat(props.donate_amount) + parseFloat(doc.val().amount),
              total_fund_needed: props.totalAmount,
            },
          });
        }
      });
    });
  };

  const getStripeFees = (event) => {
    const stripeFees = cloudFunctions.httpsCallable('getStripeChargeInfo');

    stripeFees()
      .then((result) => {
        setstripeFees(result.data);
      })
      .catch((error) => {
        console.log(`error: ${JSON.stringify(error)}`);
      });
  };

  function donateWithCrypto() {
    let params = {
      project_id: props?.params?.project_id,
      project_name: props?.projectName,
      project_description: props?.projectDescription.replaceAll(/[”]+/g, ''),
      amount: props.params?.amount,
      user_name: auth().currentUser?.displayName,
      unique_id: localStorage.getItem('unique'),
      donated_amount: props?.donate_amount,
    };
    if (props.captchaResponse === '') {
      alert('Please verify captcha');
      return;
    }
    // console.log('donateWithCrypto--params', params);
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
    axios.post('https://us-central1-caritas-revolution-438c3.cloudfunctions.net/createCharge',params,  {
        headers: headers,
      }
    )
      .then((response) => {
        // console.log('response is', response);
        let d = response.data.data;
        setOpen(false);
        window.open(
          d.hosted_url,
          '_blank' // <- This is what makes it open in a new window.
        );
      })
      .catch((error) => {
        console.log('Error is', error);
      });
  }

  return open ? (
    <SimpleBackdrop open={open} />
  ) : (
    <>
      <Grid
        container
        justifyContent="space-around"
        style={{ padding: '30px 40px 10px 40px' }}
      >
        <Grid item>
          <Button
            style={{
              textTransform: 'capitalize',
              variant: 'contained',
              backgroundColor: '#0133C0',
              borderRadius: '5px',
              color: '#fff',
              width: '250px',
              fontSize: '18px',
              marginLeft: '10px',
            }}
            onClick={() => donateWithCrypto()}
          >
            Donate {'$' + props.totalDonation}
          </Button>
        </Grid>
      </Grid>
      <div style={{ padding: '10px' }} />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignitems="center"
      >
        <Grid item xs={12}>
          <Typography>
            ${props.donate_amount.toFixed(2)} of ${props.totalAmount} raised
          </Typography>
        </Grid>

        <div style={{ padding: '20px' }} />
      </Grid>
    </>
  );
}

