import React, { } from 'react'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Box, Modal } from '@mui/material';


const BasicAlerts = ({ openCard, handleCloseCard, alertStatus, alertMessage }) => {


  return (
    <Modal
      open={openCard}
      onClose={handleCloseCard}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        // bgcolor: 'background.paper',
        // border: '2px solid #2057C0',
        boxShadow: 24,
        p: 1,
        justifyContent: 'center',
      }}>
        <Alert severity={alertStatus}>
          <AlertTitle>{alertStatus}</AlertTitle>
          {alertMessage}
        </Alert>
      </Box>
    </Modal>
  );
}

export default BasicAlerts