import React from 'react';
import styled from 'styled-components';
import Navbar from '../../../pages/Navbar'
import HeaderBar from '../../../modules/views/HeaderBar'

const Nav = styled.nav`
  display: flex;
  margin-top: ${props => (props.mobile ? '-6rem' : null)};
`;


const NavItems = ({ mobile, clicked, loggedIn }) => {
  let links;
  if (loggedIn.uid) {
    links = (<Navbar mobile={mobile} />);
  } else {
    links = (<HeaderBar />);
  }
  return <Nav mobile={mobile}>{links}</Nav>;
};


export default NavItems;
