import React, { useState } from 'react';
import {
  Grid,
  Box,
  Modal,
  TextField,
  Paper,
  FormControl,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import DialogStripe from '../../../pages/projects/my_projects/details/DialogStripeNoProject';
import { cloudFunctions } from '../../../Firebase/Firebase';
import AddCardTemp from './AddCardTemp';
import LoginModal from './LoginModal';
import AddProfileCard from './AddProfileCard';
import AuthAlert from './AuthAlert';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  app: {
    backgroundColor: '#fff',
    // [theme.breakpoints.down('xs')]: {
    //   backgroundColor: '#FFF',
    // },
  },

  logo: {
    flexGrow: 1,
    width: '170px',
    height: '180px',
  },
  img: {
    // [theme.breakpoints.only('xs')]: {
    //   width: '130px',
    // },
    // [theme.breakpoints.only('sm')]: {
    //   width: '150px',
    // },
  },
  projects: {
    color: '#0133C0',
    '&:hover': {
      color: '#214DC5',
    },
    fontSize: 16,
    textTransform: 'capitalize',
  },
  login: {
    color: '#0133C0',
    '&:hover': {
      color: '#214DC5',
    },
    fontSize: 16,
    textTransform: 'capitalize',
    // marginLeft: theme.spacing(3),
    backgroundColor: 'none',
    // [theme.breakpoints.down('xs')]: {
    //   fontSize: '15px',
    //   paddingRight: '40px',
    // },
  },
  signup: {
    // marginLeft: theme.spacing(2),
    fontSize: 16,
    textTransform: 'capitalize',
    variant: 'contained',
    backgroundColor: '#0133C0',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#214DC5',
    },
    // [theme.breakpoints.down('xs')]: {
    //   fontSize: '14px',
    //   overflow: 'hidden',
    //   marginLeft: theme.spacing(3),
    //   backgroundColor: 'none',
    // },
  },
  nav: {
    display: 'flex',
    // [theme.breakpoints.down('xs')]: {
    //   display: 'none',
    // },
  },
  sectionMobile: {
    display: 'flex',
    // [theme.breakpoints.up('sm')]: {
    //   display: 'none',
    // },
  },
  moreIcon: {
    // [theme.breakpoints.only('xs')]: {
    //   color: 'gray',
    // },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      // className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default function QuickDonationComponentWithStripe({
  _params,
  openQuickDonation,
  handleCloseQuickDonation,
  getTotalStripeFee,
  stripeFees,
}) {
  const [cards, setCards] = useState([]);
  const classes = useStyles();
  const [isPayingFees, setIsPayingFees] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState('$5.0');
  const [totalDonation, setTotalDonation] = useState('5.00');
  // eslint-disable-next-line no-unused-vars
  const [stripeFeesPerAmount, setStripeFeesPerAmount] = useState(0);
  const [isPaymentMethodSelected, setIsPaymentMethodSelected] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertStatus, setAlertStatus] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [openCard, setOpenCard] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('');

  const [openProfileCard, setOpenProfileCard] = useState(false);
  const handleOpenProfileCard = () => setOpenProfileCard(true);
  const handleCloseProfileCard = () => {
    setOpenProfileCard(false);
    donate(_params);
  };


  const [openAuthAlert, setOpenAuthAlert] = useState(false);
  const handleOpenAuthAlert = () => setOpenAuthAlert(true);
  const handleCloseAuthAlert = () => {
    setOpenAuthAlert(false);
  };

  const [openLoginCard, setOpenLoginCard] = useState(false);
  const handleOpenLoginCard = () => setOpenLoginCard(true);
  const handleCloseLoginCard = () => {
    setOpenLoginCard(false);
    donate(_params);
  };
  const handleOpenCard = () => setOpenCard(true);
  const handleCloseCard = () => setOpenCard(false);

  function getTotalDonationWithStripeFees(isPayingFees, amount) {
    setIsPayingFees(isPayingFees);
    let mnt = typeof amount == 'undefined' ? selectedAmount : amount;
    const _am = mnt.toString().startsWith('$')
      ? mnt.toString().substr(1)
      : mnt.toString();
    const _part1 = parseFloat(_am) + parseFloat(stripeFees['stripe_fee_fixed']);
    const _part2 = 1 - parseFloat(stripeFees['stripe_fee_percentage']) / 100;
    const _amount = _part1 / _part2;
    _params['amount'] = _amount.toFixed(2);
    let total = isPayingFees ? _amount.toFixed(2) : parseFloat(_am).toFixed(2);
    setTotalDonation(total);
  }

  function getSelectedAmount(e) {
    e.preventDefault();
    let amount =
      typeof e.target.value == 'undefined' ? selectedAmount : e.target.value;

    setSelectedAmount(amount);
    getTotalDonationWithStripeFees(isPayingFees, amount);
    _params['amount'] = e.target.value.toString().substr(1);
    getTotalStripeFee(amount);
  }

  const cardComponents = cards.map((card, index) => (
    <div style={{ padding: '6px', width: '100%' }} key={index}>
      <Button
        variant="outlined"
        color="primary"
        style={{
          justifyContent: 'flex-start',
          textTransform: 'capitalize',
          width: '100%',
        }}
      >
        <FormControlLabel
          value={'**** **** **** ' + card['last4']}
          control={<StyledRadio />}
          label={'**** **** **** ' + card['last4']}
        />
      </Button>
    </div>
  ));

  function handleCustomAmount(e) {
    e.preventDefault();
    setSelectedAmount(e.target.value);
  }


  const makeDonation = (params) => {
    setOpen(true);
    const donation = cloudFunctions.httpsCallable('globalDonation');

    donation(params)
      .then((result) => {
        if (result.data['status'] === 200) {
          setOpen(false);
          setAlertStatus('success');
          setAlertMessage(result.data.message);
          handleOpenCard();
        } else if (result.data['status'] === 400) {
          setAlertStatus('error');
          setAlertMessage(result.data.message);
          handleOpenCard();
          setOpen(false);
        }
      })
      .catch((error) => {
        console.log(`error==: ${JSON.stringify(error)}`);
        setOpen(false);
      });
  };


  async function donate(params) {
    // console.log('params-donate', params);
    if (isPaymentMethodSelected && typeof params['pm_token'] !== 'undefined') {
      if (params['pm_token'].length <= 0) {
        alert('Please add or select a card.');
      }
      else if (params['amount'] < stripeFees['min_transaction_amount']) {
        alert(
          'Donation amount cannot be less than $',
          stripeFees['min_transaction_amount']
        );
      } 
      else {
        if (params['user_name'] === '') {
          handleOpenAuthAlert();
        } else {
          makeDonation(params);
        }
      }
    }
    else {
      alert('Please select a card');
    }
    if (captchaToken == '') {
      console.log('Please verify captcha'+captchaToken);
      alert('Please verify captcha');
      setOpen(false);
      return;
    }
  }

  return (
    <Modal
      open={openQuickDonation}
      onClose={handleCloseQuickDonation}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #2057C0',
          boxShadow: 24,
          p: 4,
          justifyContent: 'center',
          alignitems: 'center',
          alignContent: 'center',
        }}
      >
        <Paper elevation={0}>
          <Paper className={classes.paper} elevation={0}>
            <FormControl component="fieldset" style={{ width: '100%' }}>
              <Grid container>
                <Grid
                  item
                  container
                  xs={12}
                  style={{ justifyContent: 'center' }}
                >
                  <Typography style={{ fontWeight: 'bold', fontSize: '18px' }}>
                    Quick Donation
                  </Typography>
                </Grid>
                <div style={{ padding: '2px' }} />
                <Grid item xs={12}>
                  <RadioGroup
                    defaultValue="$5.0"
                    aria-label="donation"
                    name="customized-radios"
                    onChange={getSelectedAmount}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ justifyContent: 'flex-start' }}
                    >
                      <FormControlLabel
                        value="$5.0"
                        control={<StyledRadio />}
                        label="$5.0"
                      />
                    </Button>
                    <div style={{ padding: '6px' }} />
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ justifyContent: 'flex-start' }}
                    >
                      <FormControlLabel
                        value="$10.0"
                        control={<StyledRadio />}
                        label="$10.0"
                      />
                    </Button>
                    <div style={{ padding: '6px' }} />
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ justifyContent: 'flex-start' }}
                    >
                      <FormControlLabel
                        value="$20.0"
                        control={<StyledRadio />}
                        label="$20.0"
                      />
                    </Button>
                    <div style={{ padding: '6px' }} />
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ width: '100%', justifyContent: 'flex-start' }}
                    >
                      <FormControlLabel
                        value={selectedAmount}
                        control={<StyledRadio />}
                        label={
                          <TextField
                            id="standard-bare"
                            defaultValue="$0"
                            size="small"
                            InputProps={{ disableUnderline: true }}
                            onChange={handleCustomAmount}
                          />
                        }
                      />
                    </Button>
                  </RadioGroup>
                </Grid>
              </Grid>
              <div style={{ padding: '2px' }} />
            </FormControl>
            <div
              style={{
                padding: '2px',
                justifyContent: 'center',
                width: '100%',
              }}
            />
            <Grid container style={{ width: '100%', justifyContent: 'center' }}>
              <Grid item container xs={12} style={{ justifyContent: 'center' }}>
                <Typography style={{ fontWeight: 'bold', fontSize: '18px' }}>
                  Payment Details
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={12}
                style={{ width: '100%', justifyContent: 'center' }}
              >
                <RadioGroup
                  key={cards[0]?.last4}
                  defaultValue={
                    '**** **** **** ' + cards[0]?.last4
                      ? '**** **** **** ' + cards[0]?.last4
                      : ' '
                  }
                  aria-label="donation"
                  name="customized-radios"
                  style={{ width: '100%' }}
                >
                  {cardComponents}
                </RadioGroup>
              </Grid>

              <div style={{ padding: '2px' }} />
              <AddCardTemp
                _params={_params}
                setCards={setCards}
                setIsPaymentMethodSelected={setIsPaymentMethodSelected}
              />

              <AddProfileCard
                _params={_params}
                openProfileCard={openProfileCard}
                handleCloseProfileCard={handleCloseProfileCard}
                handleOpenLoginCard={handleOpenLoginCard}
              />

              <AuthAlert
                openAuthAlert={openAuthAlert}
                handleCloseAuthAlert={handleCloseAuthAlert}
                handleOpenProfileCard={handleOpenProfileCard}
                handleOpenLoginCard={handleOpenLoginCard}
              />

              <LoginModal
                _params={_params}
                openLoginCard={openLoginCard}
                handleOpenLoginCard={handleOpenLoginCard}
                handleCloseLoginCard={handleCloseLoginCard}
              />

              <div style={{ padding: '10px' }} />
              <Grid item container xs={12} style={{ justifyContent: 'center' }}>
                <Typography
                  style={{ fontSize: '16px', alignContent: 'center' }}
                >
                  Stripe transaction fee is ${stripeFeesPerAmount} for this
                  donation. Would you like to pay it?
                </Typography>
              </Grid>

              {/* {console.log('totalDonation', totalDonation + "\n" + "isPaymentMethodSelected" + isPaymentMethodSelected + "\n" + 'stripeFees' + stripeFees + "\n" + "_params" + _params + "\n" + 'getTotalDonationWithStripeFees' + getTotalDonationWithStripeFees + "\n" + 'selectedAmount' + selectedAmount + "\n" + 'stripeFeePerAmount' + stripeFeesPerAmount + "\n" + 'handleOpenProfileCard' + handleOpenProfileCard + "\n" + 'handleCloseQuickDonation' + handleCloseQuickDonation + "\n" + 'donate' + donate + "\n" + 'openCard' + openCard + "\n" + 'handleOpenCard' + handleOpenCard + "\n" + 'handleCloseCard' + handleCloseCard + "\n" + 'setOpen' + setOpen + "\n" + 'open' + open + "\n" + 'alertStatus' + alertStatus + "\n" + 'alertMessage' + alertMessage)} */}

              <DialogStripe
                totalDonation={totalDonation}
                isPaymentMethodSelected={isPaymentMethodSelected}
                stripeFees={stripeFees}
                params={_params}
                getTotalDonationWithStripeFees={(
                  isPayingFees,
                  selectedAmount
                ) =>
                  getTotalDonationWithStripeFees(isPayingFees, selectedAmount)
                }
                selectAmount={selectedAmount}
                stripeFeePerAmount={stripeFeesPerAmount}
                handleOpenProfileCard={handleOpenProfileCard}
                handleCloseQuickDonation={handleCloseQuickDonation}
                donate={donate}
                openCard={openCard}
                handleOpenCard={handleOpenCard}
                handleCloseCard={handleCloseCard}
                setOpen={setOpen}
                open={open}
                alertStatus={alertStatus}
                alertMessage={alertMessage}
                captchaToken={captchaToken}
              />
            </Grid>
          </Paper>
        </Paper>
      </Box>
    </Modal>
  );
}
