import { Backdrop, Box, Button, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { auth } from "../../Firebase/Firebase";
import * as actions from "../../store/actions";

const DeleteAccount = ({ deleteUser, cleanUp }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const user = auth().currentUser;
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      //   onClick={() => setOpen(false)}
    >
      <Modal
        open={open}
        // onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete Your Account
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to delete your account?
          </Typography>
          <Box display={"flex"} gap={2} justifyContent={"end"}>
            <Button
              variant="outlined"
              onClick={async () => {
                await deleteUser();
                navigate("/");
              }}
            >
              Submit
            </Button>
            <Button variant="outlined" onClick={() => navigate("/my-profile")}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Backdrop>
  );
};

const mapStateToProps = ({ firebase, auth }) => ({
  firebase,
  loading: auth.deleteUser.loading,
  error: auth.deleteUser.error,
});

const mapDispatchToProps = {
  deleteUser: actions.deleteUser,
  cleanUp: actions.clean,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccount);
