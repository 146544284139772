export const firebaseSecrets = {
   apiKey: "AIzaSyDgh8pUHpT1odI9HhgVpR4pxNcCznDd2Tw",
   authDomain: "caritas-revolution-438c3.firebaseapp.com",
   databaseURL: "https://caritas-revolution-438c3.firebaseio.com",
   projectId: "caritas-revolution-438c3",
   storageBucket: "caritas-revolution-438c3.appspot.com",
   messagingSenderId: "485794128776",
   appId: "1:485794128776:web:c1d1003dbbd73b5206d03d"
}
export const salesforceBackURL =
  "https://us-central1-caritas-revolution-438c3.cloudfunctions.net/app/";
