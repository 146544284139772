import React from "react";
import { withStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
// import { Button, } from "@mui/material";
// import { Link } from "react-router-dom";
import MobileStoreButton from "react-mobile-store-button";
import iosImage from "../../images/ios_logo.png"
import androidImage from "../../images/android_logo.png" 
import Line from "./Line";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "98%",
    overflow: "hidden",

    [theme.breakpoints.only("xl")]: {
      // overflow: "hidden",
    },
    [theme.breakpoints.only("lg")]: {
      // overflow: "hidden",
    },
    [theme.breakpoints.down("xs")]: {
      // display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      // display: "none",
    },
    [theme.breakpoints.only("md")]: {
      // height: '600px',
      // width: '100%',
    },
  },
  image: {
    width: "200px",
  },
  card: {
    display: "flex",
    backgroundColor: "#E7F6F7",
    borderRadius: "25px",
  },
  button: {
    marginTop: theme.spacing(-1),
    // marginLeft: theme.spacing(2),
    fontSize: "15px",
    textTransform: "capitalize",
    fontWeight: "bold",
    width: "200px",
    borderRadius: "20px",
    backgroundColor: "#13B0BB",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#13A4AE",
    },
  },
  appstore: {
    width: "150px",
  },
  appgoogle: {
    width: "150px",
  },
  mobile: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "100%",
    overflow: "hidden",
    [theme.breakpoints.only("xl")]: {
      display: "none",
    },
    [theme.breakpoints.only("lg")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      display: "none",
    },
  },
  mobilecard: {
    backgroundColor: "#E7F6F7",
    borderRadius: "25px",
  },
  mobilebutton: {
    fontSize: "15px",
    textTransform: "capitalize",
    fontWeight: "bold",
    width: "195px",
    borderRadius: "20px",
    backgroundColor: "#13B0BB",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#13A4AE",
    },
  },
  mobileapple: {
    width: "120px",
  },
  mobilegoogle: {
    width: "120px",
  },
  login: {
    fontSize: 16,
    textTransform: "capitalize",
    variant: "contained",
    backgroundColor: "#fff",
    color: "#214DC5",
    "&:hover": {
      backgroundColor: "#214DC5",
      color: "#fff",
    },
  },
  signup: {
    fontSize: 44,
    textTransform: "capitalize",
    variant: "contained",
    backgroundColor: "black",
    color: "#fff",
    "&:hover": {
      backgroundColor: "black",
    },
    borderTopWidth: 4,
    borderBottomWidth: 4,
    borderLeftWidth: 4,
    borderRightWidth: 4,
    borderColor: "white",
    borderStyle: "solid",
    width: "200px",
    height: "150px",
  },
  shapeColor: {
    backgroundColor: "#2057C0",
    borderRadius: "50px",
    marginTop: "20px",
    borderTopWidth: 2,
    borderBottomWidth: 2,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderColor: "grey",
    borderStyle: "solid",
  },
});

function Device(props) {
  const { classes } = props;
  const iOSUrl =
    "https://apps.apple.com/us/app/caritas-revolution/id1484835135";
  const androidUrl =
    "https://play.google.com/store/apps/details?id=com.caritasrerum.caritasrevolution";

  return (
    <div className={classes.root}>
      <Grid container justifyContent={"center"}>
        <Grid
          item
          xs={10}
          sm={10}
          md={10}
          lg={10}
          xl={10}
          align="center"
          style={{
            padding: "2.5rem",
            paddingTop: "1.7rem",
            backgroundColor: "#fff",
            color: "#000",
            // boxShadow: "rgb(206 206 206) 0px 0px 10px",
            borderRadius: "10px",
            fontFamily: "Poppins",
            marginTop: "1rem",
          }}
        >
          {/* <div>
            <Typography
              variant="h4"
              Typographyed="center"
              align="center"
              component="h2"
              style={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "32px",
                padding: "10px",
              }}
            >
              OUR APP
            </Typography>
          </div>

          <Grid container justifyContent={"center"} style={{ paddingTop: "10px" }}>
            <Grid item>
              <Button
                to="/signup"
                component={Link}
                style={{
                  backgroundColor: "#214DC5",
                  padding: ".5rem 4rem",
                  color: "#fff",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                Sign Up
              </Button>
            </Grid>
          </Grid>

          <Grid container justifyContent={"center"}>
            <Grid item>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "600",
                  marginTop: "30px",
                }}
              >
                OR
              </Typography>
            </Grid>
          </Grid> */}

          <Grid container justifyContent={"center"}>
            <Grid item>
              <Typography
                style={{
                  fontSize: "32px",
                  fontWeight: "600",
                  padding: "10px",
                  fontStyle: "normal",
                }}
              >
                DOWNLOAD OUR APP
              </Typography>
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Grid>
              <img src={iosImage} width={"200px"} style={{cursor:"pointer"}} onClick={()=>window.open(iOSUrl)}/>
              {/* <MobileStoreButton
                store="ios"
                width={228}
                height={90}
                url={iOSUrl}
                linkProps={{ title: "iOS Store Button" }}
              /> */}
            </Grid>

            <Grid>
              <img src={androidImage} width={"200px"} style={{cursor:"pointer"}} onClick={()=>window.open(androidUrl)}/>
              {/* <MobileStoreButton
                store="android"
                width={228}
                height={90}
                url={androidUrl}
                linkProps={{ title: "Android Store Button" }}
              /> */}
            </Grid>
          </div>
        </Grid>

        <br />
        <br />
        <br />

        {/* <div
          style={{
            marginBottom: "20px",
            marginTop: "20px",
            paddingTop: "10px",
          }}
        >
          <Typography
            align="center"
            style={{
              fontFamily: "Mulish",
              fontStyle: "normal",
              fontWeight: 200,
              fontSize: "10px",
            }}
          >
            
            Caritas Revolution ™ is a program owned by Caritas Rerum a
            charitable organization and exempt from federal income tax under
            Internal Revenue Code Section 501(c)(3).Bakers can therefore deduct
            their contribution as described under IRC Section 170.6.
            Tax-deductible Contributions.
          </Typography>
        </div> */}
        <Line />
      </Grid>
    </div>
  );
}

export default withStyles(styles)(Device);
