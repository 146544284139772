/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import { Button, IconButton, Grid, CardMedia, Tooltip, AppBar, Menu, MenuItem, useMediaQuery } from "@mui/material";
import MoreIcon from '@mui/icons-material/MoreVert';
import { Link } from "react-router-dom";
import QuickDonationComponentWithStripe from "./components/QuickDonationComponentWithStripe";
import QuickDonationComponentWithCrypto from "./components/QuickDonationComponentWithCrypto";
import { cloudFunctions } from "../../Firebase/Firebase";
import QuickDonationAlert from "./components/QuickDonationAlert";
import { useTheme } from "styled-components";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
  },
  app: {
    border: '1px solid red',
    backgroundColor: "#FFF",
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "#FFF",
    },
  },

  logo: {
    flexGrow: 1,
    width: "230px",
    height: "35px",
  },

  img: {
    [theme.breakpoints.only("xs")]: {
      width: "130px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "150px",
    },
  },
  projects: {
    color: "#0133C0",
    fontWeight: "bold",
    "&:hover": {
      color: "#214DC5",
    },
    fontSize: 18,
    textTransform: "capitalize",
  },
  login: {
    color: "#0133C0",
    fontWeight: "bold",
    "&:hover": {
      color: "#214DC5",
    },
    fontSize: 18,
    textTransform: "capitalize",
    marginLeft: theme.spacing(3),
    backgroundColor: "none",
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
      paddingRight: "40px",
    },
  },
  signup: {
    marginLeft: theme.spacing(2),
    fontSize: 18,
    textTransform: "capitalize",
    variant: "contained",
    backgroundColor: "#0133C0",
    fontWeight: "bold",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#214DC5",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      overflow: "hidden",
      marginLeft: theme.spacing(3),
      backgroundColor: "none",
    },
  },
  quickSmall: {
    [theme.breakpoints.down("md")]: {
      overflow: "hidden",
      backgroundColor: "#214DC5",
      fontSize: "10px",
      variant: "contained",
      color: "#fff",
    },
  },
  nav: {
    display: "flex",
    fontWeight: "bold",
    // [theme.breakpoints.down("xs")]: {
    //   display: "none",
    // },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  moreIcon: {
    [theme.breakpoints.only("xs")]: {
      color: "gray",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 18,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
}));

function NavBar(props) {
  const isMatch = useMediaQuery("(min-width: 600px)");
  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [open, setOpen] = useState(false);
  const [openQuickDonation, setOpenQuickDonation] = React.useState(false);
  const [openQuickDonationCrypto, setOpenQuickDonationCrypto] =
    React.useState(false);
  const [cards, setCards] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleOpenQuickDonation = () => {
    setOpen(false);
    setOpenQuickDonation(true);
  };
  const handleOpenQuickDonationCrypto = () => {
    setOpen(false);
    setOpenQuickDonationCrypto(true);
  };
  const handleClose = () => setOpen(false);
  const handleCloseQuickDonation = () => setOpenQuickDonation(false);
  const handleCloseQuickDonationCrypto = () =>
    setOpenQuickDonationCrypto(false);
  const [stripeFeesPerAmount, setStripeFeesPerAmount] = useState(0);
  const [stripeFees, setStripeFees] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState("$5.0");
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";



  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Grid container direction="column">
          <Grid item>
            <Button to="/our-story" component={Link} className={classes.login}>
              Our Story
            </Button>
          </Grid>
          <Grid item>
            <Button to="/contact-us" component={Link} className={classes.login}>
              Contact
            </Button>
          </Grid>
          <Grid item>
            <Button to="/login" component={Link} className={classes.login}>
              Login
            </Button>
          </Grid>
          <Grid item>
            <Button to="/signup" component={Link} className={classes.signup}>
              Signup
            </Button>
          </Grid>
        </Grid>
      </MenuItem>
    </Menu>
  );

  const _params = {
    user_name: "",
    user_email: "",
    customer_id: "",
    amount: selectedAmount.toString().substr(1),
    pm_token: cards?.length <= 0 ? "" : cards[0]["id"],
    timestamp: Date.now(),
    paying_stripe_fee: false,
    stripe_charge_id: stripeFees["stripe_charge_id"],
    save_card_for_future: false,
  };

  const getStripeFees = async (event) => {
    const _stripeFees = await cloudFunctions.httpsCallable(
      "getStripeChargeInfo"
    );

    _stripeFees()
      .then((result) => {
        setStripeFees(result.data);
        _params["stripe_charge_id"] = result.data.stripe_charge_id;
      })
      .catch((error) => {
        console.log(`error: ${JSON.stringify(error)}`);
      });
  };

  function getTotalStripeFee(_donation) {
    const _part1 = parseFloat(_donation.substr(1)) + parseFloat(stripeFees["stripe_fee_fixed"]);
    const _part2 = 1 - parseFloat(stripeFees["stripe_fee_percentage"]) / 100;
    const _amount = _part1 / _part2;
    const _stripeFee = _amount - _donation.substr(1);
    setStripeFeesPerAmount(_stripeFee.toFixed(2));
  }

  useEffect(() => {
    getStripeFees();
    getTotalStripeFee(selectedAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const inlinemedia = {
  //   backgroundColor: "#fff",
  //   // Adding media query..
  //   '@media (min-width: 320px) and (max-width: 480px)': {
  //     display: 'none !important',
  //   },
  // };
  // console.log("stripeFees", );

  return (
    <>
      <AppBar style={{ background: "#fff" }} position="fixed" elevation={0}>
        <Toolbar style={{ height: "40px", justifyContent: "space-between" }}>
          <Grid xl={4} sm={4} md={5} container style={{ width: "100%" }}>
            <Link to="/">
              <CardMedia
                className={classes.logo}
                alt="logo"
                image="/logo.jpg"
              />
            </Link>
          </Grid>
          {isMatch == true ? (
            <Grid xs={6} container justifyContent="flex-end" className={classes.nav}>
              <Grid item>
                <Tooltip title="Our Story">
                  <Button style={{ color: "#0133C0" }}
                    to="/our-story"
                    component={Link}
                    className={classes.login}
                  >
                    Our Story
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Contact Us">
                  <Button
                    style={{ color: "#0133C0" }}
                    to="/contact-us"
                    component={Link}
                    className={classes.login}

                  >
                    Contact us
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Click to login">
                  <Button
                    to="/login"
                    component={Link}
                    className={classes.login}
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.04)", marginLeft: "10px", marginRight: "10px", color: "#0133C0" }}
                  >
                    Login
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Click to Signup">
                  <Button
                    to="/signup"
                    component={Link}
                    className={classes.signup}
                    style={{ backgroundColor: '#0133C0', color: "white", marginLeft: "10px", marginRight: "10px" }}
                  >
                    Signup
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Donate in less than 1 min">
                  <Button
                    to="/"
                    onClick={handleOpen}
                    component={Link}
                    className={classes.signup}

                    style={{
                      background: "rgb(100 21 21)",
                      color: "white"
                    }}
                  >
                    QUICK DONATION
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        height: "1.5rem",
                        width: "1.5rem",
                        marginBottom: "3px",
                        marginLeft: "8px",
                      }}
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          ) : (null)
          }
          <div className={classes.sectionMobile}>
            <Tooltip title="Donate in less than 1 min">
              <Button
                to="/"
                onClick={handleOpen}
                component={Link}
                className={classes.quickSmall}
                style={{
                  background: "rgb(100 21 21)",
                  color: "white",
                }}
              >
                QUICK DONATION
              </Button>
            </Tooltip>

            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon className={classes.moreIcon} />
            </IconButton>
          </div>

          <QuickDonationAlert
            open={open}
            handleClose={handleClose}
            handleOpenQuickDonation={handleOpenQuickDonation}
            handleOpenQuickDonationCrypto={handleOpenQuickDonationCrypto}
          />
          <QuickDonationComponentWithStripe
            handleOpenQuickDonation={handleOpenQuickDonation}
            openQuickDonation={openQuickDonation}
            handleCloseQuickDonation={handleCloseQuickDonation}
            _params={_params}
            getTotalStripeFee={getTotalStripeFee}
            stripeFees={stripeFees}
          />
          <QuickDonationComponentWithCrypto
            openQuickDonationCrypto={openQuickDonationCrypto}
            handleCloseQuickDonationCrypto={handleCloseQuickDonationCrypto}
          />
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </>
  );
}
NavBar.propTypes = {
  mobileOpen: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};
export default NavBar;

