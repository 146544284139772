import { useLocation, Routes, Route, Navigate } from "react-router-dom";
import WOW from "wowjs";
import '../src/App.css';
import VerifyEmail from "./containers/Auth/VerifyEmail/VerifyEmail";
import Logout from "./containers/Auth/Logout/Logout";
import Profile from "./pages/profile/Profile";
import Home from "./containers/Home/Home";
import { connect } from "react-redux";
import Landing from "./containers/Home/Landing";
import React, { useEffect, Suspense } from "react";
import ReactGA from "react-ga";
import TagManager from 'react-gtm-module';
import * as actions from "./store/actions";
import Story from "./containers/Home/Story";
import Layout from "./hoc/layout/Layout";
import Contact from "./containers/Contact";
import ContactAbout from "./containers/ContactAbout";
import About from "./modules/views/About";
import Terms from "./containers/Terms";
import Privacy from "./containers/PrivacyPolicies";
import RecoverPassword from "./containers/Auth/RecoverPassword/RecoverPassword";
import SignUp from "./containers/Auth/SignUp/SignUp";
import Login from "./containers/Auth/Login/Login";
import TermCondition from "./containers/TermCondition";
import DetailsFree from "./pages/projects/my_projects/details/DetailsFree";
import My_Projects from "./pages/profile/My_Projects";
import DeleteAccount from "./pages/profile/deleteAccount";
import Transactions from "./pages/profile/Transactions";
import TransacHistory from "./pages/causes/TransacHistory";
import Payment from "./containers/Home/Payment";
import Settings from "./pages/profile/Settings";
import Card from "./pages/Card";
import My_Causes from "./pages/profile/My_Causes";
import Details from "./pages/projects/my_projects/details/Details";
import DonationWithStripe from "./pages/projects/my_projects/details/DonationWithStripe";
import DonationWithCrypto from "./pages/projects/my_projects/details/DonationWithCrypto";
import Thanks from "./containers/Home/Thanks";
import Edit from "./containers/Home/Edit";
import CryptoEmbed from "./pages/projects/my_projects/details/CryptoEmbed";
import CardProjectRaise from "./pages/projects/my_projects/details/CardProjectRaise";
import Preference from "./pages/Preference";
import CausesGrid from "./pages/CausesGrid";
import TotalOverview from "./pages/projects/my_projects/details/TotalOverview";
import Projects from "./containers/Home/Projects";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// const withoutSidebar = (Component) => <Component />;

const App = ({ loggedIn, emailVerified, getSignInCredentials, isQuickDonation, }) => {

  let routes;
  const theme = createTheme();
  const history = useLocation();
  ReactGA.initialize("UA-79382747-2");
  ReactGA.pageview(window.location.pathname + window.location.search);

  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-WR5TLR3" });
  });

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  // if (loggedIn && emailVerified && history?.pathname === "/login") {
  // // console.log("loggedIn =  " + loggedIn + " emailVerified = " + emailVerified + " history.pathname = " + history?.pathname)
  // }

  if (loggedIn && !emailVerified) {
    routes = (
      <Routes>
        <Route exact path="/verify-email" element={<VerifyEmail />} />
        <Route exact path="/my-profile" element={<Profile />} />
        <Route exact path="/logout" element={<Logout />} />
        {/* <Redirect to="/verify-email" /> */}
        <Route path="/" element={<Navigate to="/verify-email" replace />} />
        <Route path="/signup" element={<Navigate to="/verify-email" replace />} />
      </Routes>
    );
  }
  else if (loggedIn && emailVerified) {
    if (history?.pathname === "/login") {
      // // console.log("loggedIn if data =  " + loggedIn + " emailVerified = " + emailVerified + " history.pathname = " + history?.pathname)
      routes = (
        <Routes>
          <Route path="/login" element={<Navigate to="/home" replace />} />
        </Routes>
      );
    }
    else {
      routes = (
        <Suspense fallback={<div> Loading...</div>}>
          <Routes>
            <Route exact path="/" element={<Landing />} />
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route exact path="/projects" element={<Projects />} />
            <Route exact path="/my-causes" element={<My_Causes />} />
            <Route exact path="/my-projects" element={<My_Projects />} />
            <Route
              exact
              path="/transactions"
              element={<Transactions />}
            />
            <Route
              exact
              path="/transacthistory"
              element={() => { <TransacHistory /> }}
            />
            <Route
              exact

              path="/settings"
              element={() => { <Settings /> }}
            />
            <Route
              exact
              path="/my-profile" element={<Profile />}
            />
            <Route exact path="/delete-account" element={<DeleteAccount />} />
            <Route exact path="/payment" element={<Payment />} />
            <Route exact path="/total-overview" element={<TotalOverview />} />
            <Route exact path="/card" element={<Card />} />
            <Route exact path="/projectraise" element={<CardProjectRaise />} />
            <Route exact path="/details" element={<Details />} />
            <Route exact path="/donation-with-stripe" element={<DonationWithStripe />} />
            <Route
              exact
              path="/donation-with-crypto"
              element={<DonationWithCrypto />}
            />

            <Route exact path="/edit" element={<Edit />} />
            <Route exact path="/thanks" element={<Thanks />} />
            <Route exact path="/cryptoembed" element={<CryptoEmbed />} />
            <Route exact path="/causes-grid" element={<CausesGrid />} />
            <Route exact path="/register" element={<Preference />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/contact" element={<ContactAbout />} />
            <Route exact path="/termconditions" element={<TermCondition />} />
            <Route exact path="/contact-us" element={<Contact />} />
            <Route exact path="/terms" element={<Terms />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/about" element={<About />} />
            {/* <Redirect to="/home" /> */}
            <Route path="/home" element={<Navigate to="/home" replace />} />
          </Routes>
        </Suspense>
      );
    }
  }
  else {
    routes = (
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/our-story" element={<Story />} />
        <Route exact path="/Login" element={<Login />} />
        <Route exact path="/Signup" element={<SignUp />} />
        <Route exact path="/recover" element={<RecoverPassword />} />
        <Route exact path="/contact-us" element={<Contact />} />
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<ContactAbout />} />
        <Route exact path="/details" element={<DetailsFree />} />
        <Route exact path="/termconditions" element={<TermCondition />} />
        {/* <Redirect to="/" /> */}
        <Route path="/" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Layout>{routes}</Layout>
    </ThemeProvider>
  );
};

const mapStateToProps = ({ firebase }) => ({
  loggedIn: firebase.auth.uid,
  emailVerified: firebase.auth.emailVerified,
});

const mapDispatchToProps = {
  getSignInCredentials: actions.getSignInCredentials
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

