import React from "react";
import HeaderBar from "../../modules/views/HeaderBar";
import Footer from "../../modules/views/Footer";
import {makeStyles} from "@mui/styles";
import {Grid, Typography} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        overflow: "hidden",

        [theme.breakpoints.only("xs")]: {
            marginTop: "-280px",
        },
        [theme.breakpoints.only("sm")]: {
            marginTop: "-500px",
        },
        [theme.breakpoints.between("md", "lg")]: {
            marginTop: "-90px",
        },
        [theme.breakpoints.only("xl")]: {
            marginTop: "-300px",
        },
    },
    text: {
        textAlign: "center",
        margin: "5px",
        [theme.breakpoints.only("xs")]: {
            textAlign: "justify",
            marginTop: "20px",
            fontFamily: "Poppins",
        },
    },
    shapeColor: {
        backgroundColor: "#2057C0",
        borderRadius: "50px",
        marginTop: "100px",
        borderTopWidth: 2,
        borderBottomWidth: 2,
        borderLeftWidth: 2,
        borderRightWidth: 2,
        borderColor: "grey",
        borderStyle: "solid",
    },
}));

function Story() {
    const classes = useStyles();
    return (
        <div style={{backgroundColor: "#fff"}}>
            <HeaderBar/>
            <div className={`animate__animated animate__fadeInLeft ${classes.root}`} style={{marginTop: '8rem'}}>
                <br/>
                <Grid container justifyContent={"center"}>
                    <Grid
                        item
                        xs={10}
                        sm={10}
                        md={10}
                        lg={10}
                        xl={10}
                        align="left"
                        style={{
                            padding: "2.5rem",
                            paddingTop: "1.7rem",
                            backgroundColor: "#fff",
                            boxShadow: "rgb(206 206 206) 0px 0px 10px",
                            borderRadius: "10px",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                        }}
                    >
                        <br/>
                        <Typography
                            variant="h2"
                            style={{
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "32px",
                                color: "#000",
                            }}
                        >
                            OUR STORY
                        </Typography>
                        <Typography
                            style={{
                                fontStyle: "normal",
                                fontSize: "16px",
                                marginTop: "20px",
                                fontFamily: "Poppins",
                                color: "#000",
                            }}
                        >
                            Caritas Revolution is a transformative program introduced by Caritas Rerum, a dedicated
                            non-profit
                            organization. Our mission is to empower corporations and individuals to create a lasting
                            impact and
                            foster positive change in communities around the world. Caritas Rerum offers two types of
                            services:
                        </Typography>
                        <Typography
                            style={{
                                fontStyle: "normal",
                                fontSize: "16px",
                                marginTop: "20px",
                                fontFamily: "Poppins",
                                color: "#000",
                            }}
                        >
                            <b>For Corporations:</b>
                        </Typography>
                        <Typography
                            style={{
                                fontStyle: "normal",
                                fontSize: "16px",
                                marginTop: "20px",
                                fontFamily: "Poppins",
                                color: "#000",
                            }}
                        >
                            We offers corporations the opportunity to leave an indelible mark on the lives of countless
                            individuals
                            by funding the construction of hospitals, schools, or wells in Burkina Faso. When a
                            corporation's name
                            proudly adorns a hospital, it symbolizes their commitment to compassionate healthcare.
                            Similarly, a
                            school bearing their name empowers generations to thrive through education, while funding a
                            well
                            ensures clean water for communities, creating a healthier, brighter future for all.
                        </Typography>
                        <Typography
                            style={{
                                fontStyle: "normal",
                                fontSize: "16px",
                                marginTop: "20px",
                                fontFamily: "Poppins",
                                color: "#000",
                            }}
                        >
                            By partnering with Caritas Rerum, corporations exemplify their steadfast dedication to
                            corporate social
                            responsibility. Together, we can forge a world where compassion and progress harmonize to
                            create a
                            lasting impact. Through collaboration, corporations can increase their brand awareness by
                            aligning their
                            name with a hospital, school, or well in Burkina Faso, showcasing their commitment to making
                            a
                            tangible difference in communities in need.
                        </Typography>
                        <Typography
                            style={{
                                fontStyle: "normal",
                                fontSize: "16px",
                                marginTop: "20px",
                                fontFamily: "Poppins",
                                color: "#000",
                            }}
                        >
                            Furthermore, Caritas Rerum encourages corporations to empower their employees, promoting a
                            culture
                            of compassion and unity within their organizations. By engaging in individual projects
                            through Caritas
                            Revolution or assisting in funding transformative initiatives such as schools, hospitals, or
                            wells,
                            employees can make a meaningful difference. Together, we can create a profound impact and
                            work
                            towards a better world.
                        </Typography>
                        <Typography
                            style={{
                                fontStyle: "normal",
                                fontSize: "16px",
                                marginTop: "20px",
                                fontFamily: "Poppins",
                                color: "#000",
                            }}
                        >
                            <b>For Individuals:</b>
                        </Typography>
                        <Typography
                            style={{
                                fontStyle: "normal",
                                fontSize: "16px",
                                marginTop: "20px",
                                fontFamily: "Poppins",
                                color: "#000",
                            }}
                        >
                            Caritas Revolution serves as a catalyst for positive change, providing a platform for
                            individuals to
                            support small charities. Through our flagship program, individuals can empower and uplift
                            these
                            charities, enhancing their impact and improving lives in communities in need.
                        </Typography>
                        <Typography
                            style={{
                                fontStyle: "normal",
                                fontSize: "16px",
                                marginTop: "20px",
                                fontFamily: "Poppins",
                                color: "#000",
                            }}
                        >
                            We believe that financial limitations should never hinder the ability of small charities to
                            make a
                            significant impact. That's why Caritas Revolution generously offers a wide range of
                            comprehensive
                            services free of charge to these charities. By removing the burden of costs, we empower them
                            to focus
                            entirely on their mission and maximize their potential to bring about positive change.
                        </Typography>
                        <Typography
                            style={{
                                fontStyle: "normal",
                                fontSize: "16px",
                                marginTop: "20px",
                                fontFamily: "Poppins",
                                color: "#000",
                            }}
                        >
                            Financial transparency is of utmost importance to us. We hold ourselves to the highest
                            standards and
                            aim to build relationships based on trust and accountability with our donors. That's why we
                            provide
                            comprehensive and detailed financial reports to every donor, ensuring complete visibility
                            into how their
                            contributions are utilized. With Caritas Revolution, donors can have confidence that their
                            support is
                            making a tangible impact, and we strive to maintain a strong bond of trust with every donor
                            we serve.
                        </Typography>
                        <Typography
                            style={{
                                fontStyle: "normal",
                                fontSize: "16px",
                                marginTop: "20px",
                                fontFamily: "Poppins",
                                color: "#000",
                            }}
                        >
                            <b>Together, let's create a world where compassion and progress thrive and let's
                                revolutionize the way
                                charity is done!</b>
                        </Typography>
                        <Typography
                            style={{
                                fontStyle: "normal",
                                fontSize: "16px",
                                marginTop: "20px",
                                fontFamily: "Poppins",
                                color: "#000",
                            }}
                        >
                            For more information about our organization and initiatives, please visit our website at
                            www.caritasrerum.org. Or contact us here: <a href="https://www.caritasrerum.org/contact/"
                                                                         target="_blank">https://www.caritasrerum.org/contact/</a>
                        </Typography>
                        <br/>
                    </Grid>
                </Grid>
            </div>
            <div className={`wow animate__slow animate__animated animate__fadeInLeft ${classes.root}`}
                 style={{marginTop: '2rem'}}>
                <Grid container justifyContent={"center"}>
                    <Grid
                        item
                        xs={10}
                        sm={10}
                        md={10}
                        lg={10}
                        xl={10}
                        align="left"
                        style={{
                            padding: "2.5rem",
                            paddingTop: "1.7rem",
                            backgroundColor: "#fff",
                            color: "#000",
                            boxShadow: "rgb(206 206 206) 0px 0px 10px",
                            borderRadius: "10px",
                            fontFamily: "Poppins",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                        }}
                    >
                        <br/>
                        <Typography
                            variant="h2"
                            style={{
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "32px",
                            }}
                        >
                            OUR ORGANIZATION

                        </Typography>
                        <Typography
                            style={{
                                fontStyle: "normal",
                                fontSize: "16px",
                                marginTop: "20px",
                                fontFamily: "Poppins",
                                color: "#000",
                            }}
                        >
                            Caritas Revolution ™️ is a program owned by Caritas Rerum, founded in September 2016 with
                            headquarters in New York (USA). Our organization is exempt from federal income tax under
                            Internal Revenue Code Section 501(c)(3) founded in September 2016. Donors can therefore
                            deduct their contribution as described under IRC Section 170.6. Tax-deductible
                            Contributions.
                        </Typography>
                    </Grid>
                </Grid>
            </div>

            <div className={`wow animate__slower animate__animated animate__fadeInLeft ${classes.root}`}
                 style={{marginTop: '2rem'}}
            >
                <Grid container justifyContent={"center"}>

                    <Grid
                        item
                        xs={10}
                        sm={10}
                        md={10}
                        lg={10}
                        xl={10}
                        align="left"
                        style={{
                            display: 'none',
                            padding: "2.5rem",
                            paddingTop: "1.7rem",
                            backgroundColor: "#fff",
                            color: "#000",
                            boxShadow: "rgb(206 206 206) 0px 0px 10px",
                            borderRadius: "10px",
                            fontFamily: "Poppins",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                        }}
                    >
                        <br/>
                        <Typography
                            variant="h2"
                            style={{
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "32px",
                            }}
                        >
                            BOARD OF DIRECTORS
                        </Typography>
                        <Typography
                            style={{
                                fontStyle: "normal",
                                fontSize: "16px",
                                marginTop: "20px",
                                fontFamily: "Poppins",
                                color: "#000",
                            }}
                        >
                            David Pellicano, Dona Pellicano, Eline Pellicano, Ismael Nikiema, Rodolfo Noriega
                        </Typography>
                    </Grid>
                </Grid>
            </div>

            <Footer/>
        </div>
    );
}

export default Story;
