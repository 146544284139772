import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import { Grid } from '@mui/material';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    boxShadow: '0 5px 8px 0 rgba(0, 0, 0, 0.3)',
    height: '100%',
  },
  media: {
    height: 200,
  },
  title: {},
  donateButton: {
    marginLeft: '60%',
  },
  link: {
    textDecoration: 'none',
  },
});

export default function ProjectsCard(props) {
  const classes = useStyles();
  const [isFavourite, setisFavourite] = useState(false);
  let history = useNavigate();
  const [opens, setOpens] = React.useState(false);

  const handleClickOpen = () => {
    setOpens(true);
  };

  const handleClose = () => {
    setOpens(false);
  };

  function goTO() {
    if (props.recurrentDate != null) {
      history.push({
        pathname: '/edit',
        state: {
          project_data: props.project_data,
        },
      });
    } else {
      // history.push({
      //   pathname: '/donation',
      //   state: {
      //     project_data: props.project_data,
      //   }
      // })
      handleClickOpen();
    }
  }

  useEffect(() => {
    setisFavourite(props.isFavourite);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function timestampDateConverter(timesTamp) {
    const date = timesTamp.toDate();
    const day = date.getDay();
    const month = date.getMonth();
    const year = `${date.getFullYear()}`.substr(2);

    return `${day}/${month + 1}/${year}`;
  }

  return (
    <Card className={classes.root} style={{ marginBottom: 12 }}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={props.img}
          title={props.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.country} . {props.cause}{' '}
          </Typography>

          <Typography variant="h6" component="h4">
            {props.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.description.substr(0, 50)}
          </Typography>
        </CardContent>
      </CardActionArea>
      {props.recurrentDate ? (
        <Typography style={{ paddingLeft: '10px' }}>
          Next Donation on{' '}
          {timestampDateConverter(props.recurrentDate.next_donation_date)}
        </Typography>
      ) : (
        ''
      )}
      <CardActions disableSpacing>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignitems="flex-end"
        >
          <IconButton
            className={classes.donateButton}
            aria-label="settings"
            style={{ color: isFavourite ? 'red' : 'grey' }}
            onClick={() => goTO()}
          >
            <CardActionArea>
              {props.recurrentDate ? (
                <Button variant="outlined" color="primary">
                  Edit
                </Button>
              ) : (
                <Button variant="outlined" color="primary">
                  Donate
                </Button>
              )}
            </CardActionArea>
          </IconButton>
        </Grid>
      </CardActions>

      <div>
        {/* handleClickOpen */}
        <Dialog
          open={opens}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Donation Provider'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Which payment provider do you want to use ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              to={{
                pathname: '/donation-with-stripe',
                state: {
                  project_data: props.project_data,
                },
              }}
              component={Link}
            >
              Credit card
              {/* {// console.log(JSON.stringify(props) + "projectData1")} */}
            </Button>
            <Button
              to={{
                pathname: '/donation-with-crypto',
                state: {
                  project_data: props.project_data,
                },
              }}
              component={Link}
              autoFocus
            >
              Crypto
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Card>
  );
}
