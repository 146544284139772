import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { Button } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { Grid, CardMedia } from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  app: {
    backgroundColor: '#fff',
    [theme.breakpoints.down("xs")]: {
      backgroundColor: '#FFF',
    },
  },

  logo: {
    flexGrow: 1,
    width: "170px",
    height: '180px'
  },
  img: {
    [theme.breakpoints.only("xs")]: {
      width: '130px',
    },
    [theme.breakpoints.only("sm")]: {
      width: '150px',
    },
  },
  projects: {
    color: "#0133C0",
    "&:hover": {
      color: "#214DC5",
    },
    fontSize: 16,
    textTransform: 'capitalize',
  },
  content: {
    color: "#0133C0",
    "&:hover": {
      color: "#214DC5",
    },
    fontSize: 16,
    textTransform: 'capitalize',
    marginLeft: theme.spacing(3),
    backgroundColor: 'none',
    [theme.breakpoints.down("sm")]: {
      fontSize: '15px',
      paddingRight: '40px'
    },
  },
  conten: {
    fontSize: 16,
    color: "#0133C0",
    "&:hover": {
      color: "#214DC5",
    },
    backgroundColor: 'none',
    textTransform: 'capitalize',
  },
  nav: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  moreIcon: {
    [theme.breakpoints.only('xs')]: {
      color: 'gray'
    },
  }
}));
function NavProjects(props) {
  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Grid container direction='column' >

          <Grid item>
            <Button to="/projects" component={Link} className={classes.content}>Projects</Button>
          </Grid>
          <Grid item>
            <Button to="/my-projects" component={Link} className={classes.content}>My Projects</Button>
          </Grid>
          <Grid item>
            <Button to="/my-causes" component={Link} className={classes.content}>My Causes</Button>
          </Grid >
          <Grid item>
            <Button to="/my-profile" component={Link} className={classes.content}>Profile</Button>
          </Grid>
          <Grid item>
            <Button to="/logout" component={Link} className={classes.content}>Logout</Button>
          </Grid>
        </Grid >
      </MenuItem >

    </Menu >
  );

  return (
    <>
      <AppBar style={{ backgroundColor: 'white' }} position="fixed" className={classes.app} elevation={0}>
        <Toolbar style={{ height: "40px" }}>
          <Grid container>
            <Link to="/" >
              <CardMedia
                className={classes.logo}
                alt="logo"
                image="https://firebasestorage.googleapis.com/v0/b/caritas-revolution-438c3.appspot.com/o/images%2FLandingPage%2FLogo.png?alt=media&token=e4aac397-5980-436e-81ff-929d4789e4c5"
              />
            </Link>
          </Grid>
          <Grid container justifyContent='flex-end' className={classes.nav}>
            <Grid item>
            </Grid>
            <Grid item>
              <Button to="/projects" component={Link} className={classes.conten}>Projects</Button>
            </Grid>
            <Grid item>
              <Button to="/my-projects" component={Link} className={classes.conten}>My Projects</Button>
            </Grid>
            <Grid item>
              <Button to="/my-causes" component={Link} className={classes.conten}>My Causes</Button>
            </Grid >
            <Grid item>
              <Button to="/my-profile" component={Link} className={classes.conten}>Profile</Button>
            </Grid>
            <Grid item>
              <Button to="/logout" component={Link} className={classes.conten}>Logout</Button>
            </Grid>
          </Grid >
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon className={classes.moreIcon} />
            </IconButton>
          </div>
        </Toolbar >
      </AppBar >
      {renderMobileMenu}
    </>
  );
}
NavProjects.propTypes = {
  mobileOpen: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};
export default NavProjects;