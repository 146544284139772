import ReactDOM from 'react-dom/client';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import 'react-app-polyfill/stable';
import theme from './utils/theme';
import GlobalStyles from './utils/global';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import Loader from './components/UI/Loader/Loader';
import rootReducer from '././store/reducers';
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import firebase from './Firebase/Firebase';
import {
  getFirebase,
  ReactReduxFirebaseProvider,
  createFirestoreInstance
} from "react-redux-firebase";


const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const store = createStore(
  rootReducer,
  compose(applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore: () => getFirebase().firestore() })))
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <>
      <Wrapper>
        <Loader />
      </Wrapper>
      <GlobalStyles />
    </>
  </ThemeProvider>
);


root.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider
      firebase={firebase}
      config={{
        useFirestoreForProfile: true,
        userProfile: "users",
        attachAuthIsReady: true
      }}
      dispatch={store.dispatch}
      createFirestoreInstance={createFirestoreInstance}
    >
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <>
            <App />
            <GlobalStyles />
          </>
        </ThemeProvider>
      </BrowserRouter>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);
