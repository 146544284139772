import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SimpleBackdrop from '../../projects/my_projects/details/SimpleBackdrop';
// import { auth, cloudFunctions } from '../../../Firebase/Firebase';

const useStyles = makeStyles((theme) => ({
  save: {
    fontWeight: 'bold',
    width: '150px',
    fontSize: 16,
    textTransform: 'capitalize',
    backgroundColor: '#2057C0',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#214DC5',
    },
  },
  form: {
    flexGrow: 1,
    justifyContent: 'center',
    alignitems: 'center',
    width: '100%',
    // marginLeft: theme.spacing(5),
    // marginRight: theme.spacing(5),
    // marginTop: theme.spacing(10),
    // [theme.breakpoints.only("xl")]: {
    //   marginTop: theme.spacing(60),
    // },
  },
  fieldset: {
    height: '50px',
  },
}));
const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#C4F0FF',
      color: '#000',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#FCE883' },
      '::placeholder': { color: '#87BBFD' },
    },
    invalid: {
      iconColor: 'red',
      color: 'red',
    },
  },
};

export default function AddCardTemp(props) {
  // console.log("Props in add card temp: ", props);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  // const [defaultCardNumber, setDefaultCardNumber] = useState(props.defaultCardNumber);

  // const handleClickOpen = () => {
  //   setOpen(true);

  // };
  const handleClose = (e) => {
    setOpen(false);
    handleDonationWithoutSavingCard(e);
  };
  // --------------------Comment section--------------------------------
  // const handleSaveCard = async (e) => {
  //   e.preventDefault()
  //   setOpenBackdrop(true)
  //   const { error, paymentMethod } = await stripe.createPaymentMethod({
  //     type: "card",
  //     card: elements.getElement(CardElement)
  //   })
  //   if (!error) {
  //     try {
  //       setOpenBackdrop(true)
  //       const { id } = paymentMethod
  //       if (id != null) {
  //         const params = {
  //           "user_id": auth().currentUser.uid,
  //           "token": paymentMethod.id
  //         }
  //         const addPaymentMethod = cloudFunctions.httpsCallable('addPaymentMethodToUser')
  //         addPaymentMethod(params)
  //           .then((result) => {
  //             if (result.data["status"] === 200) {
  //               setOpen(false)
  //               setOpenBackdrop(false)
  //               props.handleNewCard()
  //               props.close()
  //             }
  //           }).catch((error) => {
  //             setOpen(false)
  //             setOpenBackdrop(false)
  //             console.log(`error==: ${JSON.stringify(error)}`);
  //           });
  //       }
  //     } catch (error) {
  //       console.log("Error", error)
  //       setOpenBackdrop(false)
  //     }
  //   } else {
  //     console.log(error.message)
  //   }
  // }

  // --------------------Comment section--------------------------------

  const handleDonationWithoutSavingCard = async (e) => {
    console.log("handleDonationWithoutSavingCard" + JSON.stringify(e));
    e.preventDefault();
    setOpenBackdrop(true);
    setOpen(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
    // console.log("response" + CardElement);
    // console.log("payment method" + JSON.stringify(paymentMethod));

    if (!error) {
      try {
        const { id } = paymentMethod;
        if (id != null) {
          const card = paymentMethod.card;
          // alert(card.last4)
          card['id'] = paymentMethod.id;
          props.addCardWIthoutSaving(card);
          // alert(card.last4)
          // setDefaultCardNumber(card.last4)
          // props.defaultCardNumber = defaultCardNumber
          setOpenBackdrop(false);
          setOpen(false);
          props.close();
        }
      } catch (error) {
        console.log(error.message);
        alert(error.message);
      }
    }
  };

  return (
    <>
      <form className={classes.form}>
        {open && <SimpleBackdrop open={openBackdrop} />}
        <Typography
          component="h6"
          variant="h6"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Enter your credit card
        </Typography>
        <fieldset className={classes.fieldset}>
          <Grid xs={12} sm={10} md={12}>
            <div style={{ padding: '5px' }} />
            <CardElement options={CARD_OPTIONS} />
          </Grid>
        </fieldset>
        <div style={{ padding: '10px' }} />
        <Grid container justifyContent={"center"}>
          <Grid item>
            <Button className={classes.save} onClick={(e) => handleClose(e)}>
              Add card
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
