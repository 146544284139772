import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Button, Paper } from "@mui/material";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { auth } from "../../Firebase/Firebase";
import * as actions from "../../store/actions";
import Message from "../../components/UI/Message/Message";
import * as Yup from "yup";
import styled from "styled-components";
import Textfield from "../../containers/Auth/SignUp/Textfield";
import Select from "../../containers/Auth/SignUp/Select";
import countries from "../../containers/Auth/SignUp/exports.json";
import Main from "../payment/Main";
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: "center",
    alignitems: "center",
    width: "100%",
    backgroundColor: "#FFF",
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
    marginTop: theme.spacing(10),
    [theme.breakpoints.only("xl")]: {
      marginTop: theme.spacing(20),
    },
  },
  title: {
    fontSize: 24,
    marginLeft: theme.spacing(5),
    padding: theme.spacing(3),
  },
  right: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  image: {
    marginLeft: theme.spacing(10),
    padding: theme.spacing(2),
  },
  field: {
    "& label.Mui-focused": {
      color: "#0133C0",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0133C0",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#0133C0",
      },
      "&:hover fieldset": {
        borderColor: "#0133C0",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0133C0",
      },
    },
  },
  submit: {
    width: "200px",
    fontWeight: "bold",
    backgroundColor: "#0133C0",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#214DC5",
    },
  },
  save: {
    width: "50px",
    backgroundColor: "#0133C0",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#214DC5",
    },
  },
  button: {
    fontSize: 18,
    textTransform: "capitalize",
    color: "#0133C0",
    "&:hover": {
      color: "#214DC5",
      backgroundColor: "#FFF",
    },
    marginRight: theme.spacing(3),
    fontWeight: "bold",
  },
  typography2: {
    fontWeight: "bold",
    fontSize: "18px",
    marginLeft: theme.spacing(3),
    padding: theme.spacing(1),
  },
  container: {
    marginLeft: theme.spacing(20),
  },
  paper: {
    marginTop: theme.spacing(5),
    overflow: "hidden",
  },
  content: {
    marginTop: "-50px",
  },
  content1: {
    [theme.breakpoints.only("xs")]: {
      marginTop: "-50px",
    },
  },
  title1: {
    [theme.breakpoints.only("xl")]: {
      marginTop: "120px",
    },
    [theme.breakpoints.only("xs")]: {
      paddingRight: "120px",
    },
    [theme.breakpoints.only("sm")]: {
      paddingRight: "120px",
    },
  },
}));

const MessageWrapper = styled.div`
  position: relative;
  bottom: 2rem;
  width: 100%;
  padding: 0 3rem;
`;

const ProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Required.")
    .min(3, "Too short.")
    .max(25, "Too long."),
  lastName: Yup.string()
    .required("Required.")
    .min(3, "Too short.")
    .max(25, "Too long."),
  email: Yup.string()
    .email("Invalid email.")
    .required("The email is required."),
  password: Yup.string().matches(
    /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    "Create a strong password!"
  ),
  confirmPassword: Yup.string().when("password", {
    is: (password) => password?.length > 0,
    then: Yup.string()
      .required("Confirm password.")
      .oneOf([Yup.ref("password"), null], `Password doesn't match`),
  }),
  country: Yup.string(),
  // .required('Required'),
  phone_number: Yup.number()
    .integer()
    .typeError("Please enter a valid phone number"),
  // .required('Required'),
});

const Profile = ({ firebase, editProfile, loading, error, cleanUp }) => {
  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, [cleanUp]);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const user = auth().currentUser;

  if (!firebase.profile.isLoaded) return null;

  return (
    <React.Fragment>
      <Navbar />
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.paper}>
              <Grid item className={classes.title1}>
                <h2>Profile settings</h2>
                <Typography style={{ fontFamily: "Mulish", fontSize: "18px" }}>
                  Change your profile settings here
                </Typography>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <div style={{ padding: "20px" }} />
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                  >
                    <Tab
                      label="My Profile"
                      {...a11yProps(0)}
                      style={{ textTransform: "capitalize" }}
                    />
                    <Tab
                      label="Payment Methods"
                      {...a11yProps(1)}
                      style={{ textTransform: "capitalize" }}
                    />
                  </Tabs>
                  <SwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                  >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                      <Formik
                        initialValues={{
                          firstName: firebase.profile.firstName,
                          lastName: firebase.profile.lastName,
                          phone_number: firebase.profile.phone_number,
                          country: firebase.profile.country,
                          email: firebase.auth.email,
                          password: "",
                          confirmPassword: "",
                        }}
                        validationSchema={ProfileSchema}
                        onSubmit={async (values, { setSubmitting }) => {
                          // edit the profile here
                          await editProfile(values);
                          setSubmitting(false);
                        }}
                      >
                        {({ isSubmitting, isValid }) => (
                          <Form className={classes.form}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Typography>
                                  <strong>Login as:</strong>&nbsp;
                                  {user.displayName}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Textfield
                                  name="firstName"
                                  label="First Name"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Textfield name="lastName" label="Last Name" />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Textfield name="phone_number" label="Phone" />
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <Select
                                  name="country"
                                  label="Country"
                                  options={countries}
                                />
                                <Box
                                  display={"flex"}
                                  mt={2}
                                  gap={2}
                                  justifyContent={"end"}
                                >
                                  <Button
                                    // className={classes.submit}
                                    disabled={!isValid || isSubmitting}
                                    loading={loading ? "Editing..." : null}
                                    type="submit"
                                  >
                                    Save
                                  </Button>
                                  <Button
                                    onClick={() => navigate("/delete-account")}
                                    // className={classes.submit}
                                    // disabled={!isValid || isSubmitting}
                                    loading={loading ? "Editing..." : null}
                                    type="button"
                                  >
                                    Delete
                                  </Button>
                                </Box>
                              </Grid>

                              <div style={{ padding: "10px" }} />
                              <MessageWrapper>
                                <Message error show={error}>
                                  {error}
                                </Message>
                              </MessageWrapper>
                              <MessageWrapper>
                                <Message success show={error === false}>
                                  Profile was updated!
                                </Message>
                              </MessageWrapper>
                            </Grid>
                          </Form>
                        )}
                      </Formik>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                      <form className={classes.form}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Main />
                          </Grid>

                          {/* <Grid item xs={12}>
                            <ProfileCard />
                          </Grid> */}
                        </Grid>
                      </form>
                    </TabPanel>
                  </SwipeableViews>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ firebase, auth }) => ({
  firebase,
  loading: auth.profileEdit.loading,
  error: auth.profileEdit.error,
});

const mapDispatchToProps = {
  editProfile: actions.editProfile,
  cleanUp: actions.clean,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
