import React, { useState, useEffect } from 'react';
import Markdown from 'markdown-to-jsx';
import mdDocument from '../modules/views/terms.md';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '../modules/styles/Typography';

const TermCondition = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch(mdDocument)
      .then(res => res.text())
      .then(md => { setContent(md) })
  })

  return (
    // <div><Markdown children={content} /></div>
    <React.Fragment>
      <Container>
        <Box mt={10} mb={12}>
          <Typography variant="h4" gutterBottom marked="center" align="center">
            CARITAS REVOLUTION TERMS OF USE
          </Typography>
          <div style={{ padding: '20px' }} />
          <Container maxWidth="md">
            <Markdown style={{ fontFamily: 'Verdana', textAlign: 'justify' }} textAlign="justify" children={content} />
          </Container>

        </Box>
      </Container>
      {/* <Footer /> */}
    </React.Fragment>
  )
}

export default TermCondition;