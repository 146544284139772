import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import MainForm from '../../../modules/views/MainForm'
import Button from '../../../components/UI/Forms/Button/Button';
import Message from '../../../components/UI/Message/Message';
import { Typography, Grid } from '@mui/material'
import * as actions from '../../../store/actions';
import { useNavigate } from 'react-router-dom';
import firebase from '../../../Firebase/Firebase'


// import { } from "firebase/compat/app";

const MessageWrapper = styled.div`
  position: absolute;
  bottom: -2rem;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
`;

const VerifyEmail = ({ sendVerification, error, loading, cleanUp }) => {

  const [reload, setReload] = useState(false);
  const history = useNavigate();


  useEffect(() => {
    const intervalId = setInterval(() => checkUserVerifyOrNot(), 5000);
    return () => {
      cleanUp();
      clearInterval(intervalId);
    };
  }, [cleanUp]);

  const checkUserVerifyOrNot = () => {
    if (!reload) {
      checkuser();
      console.log('not verified')
    } else {
      console.log('verified')
    }
  }

  const checkuser = () => {
    const email = localStorage.getItem('Name')
    const password = localStorage.getItem('Password')

    firebase.auth().signInWithEmailAndPassword(email, password).then((userCredential) => {
      var user = userCredential.user;
      //console.log("user------------------>" + JSON.stringify(user))
      if (user.emailVerified) {
        // console.log('verified true');
        setReload(true);
        localStorage.clear();
        history('/login');
      }
      else {
        console.log('Please verify your email')
      }
    }).catch((error) => {
      console.log(error)
    });
  }

  return (
    <Grid container justifyContent={"center"}>
      <MainForm>
        <Wrapper>
          <React.Fragment>
            <Typography variant="h4" gutterBottom marked="center" align="center">
              Verify your email
            </Typography>
            <Typography variant="body2" align="center">
              Go to your email inbox, and please verify your email.
            </Typography>
          </React.Fragment>
          <div style={{ padding: '10px' }} />
          <Button
            loading={loading ? 'Sending email...' : null}
            disabled={loading}
            onClick={() => sendVerification()}

          >
            Re-send verification email
          </Button>
          <MessageWrapper>
            <Message error show={error}>
              {error}
            </Message>
          </MessageWrapper>
          <MessageWrapper>
            <Message success show={error === false}>
              Message sent successfully!
            </Message>
          </MessageWrapper>
        </Wrapper>
      </MainForm>
    </Grid>
  );
};

const mapStateToProps = ({ auth }) => ({
  loading: auth.verifyEmail.loading,

  error: auth.verifyEmail.error,
});

const mapDispatchToProps = {
  sendVerification: actions.verifyEmail,
  cleanUp: actions.clean,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyEmail);
