import React, { createContext, useContext, useReducer } from 'react';
import { reducer, initialState } from './state';

const StateContext = createContext();

export const StateProvider = ({ children }) => (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </StateContext.Provider>
);

const useStateValue = () => useContext(StateProvider);

export default { useStateValue, StateProvider };