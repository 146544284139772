import React from 'react'
import Navbar from '../../pages/Navbar'
import Menu from '../../pages/projects/Menu'


function Projects(props) {
    return (
        <React.Fragment>
            <Navbar />
            <Menu />
        </React.Fragment>
    )
}

export default Projects;