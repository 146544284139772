import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

export default function QuickDonationAlert({ open, handleClose, handleOpenQuickDonation, handleOpenQuickDonationCrypto }) {


  return <div>
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <img alt="logo" src="/logo.jpg" style={{ margin: "1rem" }} />
        <DialogTitle id="alert-dialog-title">
          {"QUICK DONATION"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Make a donation in less than a minute! Please select your donation method:
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleOpenQuickDonation}
          >
            Credit card
          </Button>
          <Button
            onClick={handleOpenQuickDonationCrypto}
          >
            Crypto
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  </div>
}