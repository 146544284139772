import React, { useState, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Grid, Typography, Button } from '@mui/material';
import { cloudFunctions } from '../../../../Firebase/Firebase';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import SimpleBackdrop from './SimpleBackdrop';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import firebase from '../../../../Firebase/Firebase';
import axios from 'axios';
import { salesforceBackURL } from '../../../../firebaseSecrets';

export default function DialogStripe(props) {


  const [open, setOpen] = useState(false);
  const [stripeFees, setstripeFees] = useState([]);
  const [captchaToken, setCaptchaToken] = useState('');

  let history = useNavigate();


  useEffect(() => {
    getStripeFees();
  }, []);

  const getStripeFees = (event) => {
    const stripeFees = cloudFunctions.httpsCallable('getStripeChargeInfo');


    stripeFees().then((result) => {
      setstripeFees(result.data);
    }).catch((error) => {
      console.log('error', error);
    });
  };

  const makeDonation = (params) => {
    setOpen(true);
    if (captchaToken.length <= 0) {
      alert("Please verify captcha");
      setOpen(false);
      return;
    }
    let obj = {
      project_id: params.project_id,
      user_id: params.user_id,
      amount: props.totalDonation,
      stripeChargeId: params.stripe_charge_id,
      project_name: props.projectName,
      payingStripeFee: props.stripeFeePerAmount,
    };
    const donation = cloudFunctions.httpsCallable("donate");
    donation(params)
      .then(async (result) => {
        console.log(result, "result donate");
        let firestore = firebase.firestore();
        // console.log('test8' + JSON.stringify(result));
        // await firestore.collection("donations").doc().set(donation);
        let query_addDonation = await firestore
          .collection("donations")
          .where("project_id", "==", obj.project_id)
          .get();
        console.log(query_addDonation, "addndpd");
        if (query_addDonation.docs.length > 0) {
          if (
            query_addDonation.docs[0].data() !== null &&
            query_addDonation.docs[0].data() !== undefined
          ) {
            let newDonation = query_addDonation.docs[0].data();
            console.log(newDonation, "newDonatin");
            newDonation["id"] = query_addDonation.docs[0].id;
            newDonation["amount"] = obj.amount;
            await axios.post(
              salesforceBackURL + "addNewDonation",
              JSON.parse(JSON.stringify(newDonation))
            );
          }
        }
        if (result.data["status"] === 200) {
          // console.log('test2');
          setOpen(false);
          // console.log('test9');
          history({
            pathname: "/thanks",
            state: {
              project_description: props.projectDescription,
              total_amount_donated:
                parseFloat(props.donate_amount) +
                parseFloat(props.params["amount"]),
              total_fund_needed: props.totalAmount,
            },
          });
        } else if (result.data["status"] === 400) {
          // console.log('test4');
          console.log("Error " + result.data.message);
          setOpen(false);
        }
        // console.log('test5');
      })
      .catch((error) => {
        // console.log('test6');
        console.log("error==: " + error);
        setOpen(false);
      });
  };

  async function checkValidation(params) {
    if (
      props.isPaymentMethodSelected &&
      typeof params['pm_token'] !== 'undefined'
    ) {
      if (params['pm_token'].length <= 0) {
        alert('Please add or select a card.');
      }
      else if (params['amount'] < props.stripeFees['min_transaction_amount']) {
        alert(
          'Donation amount cannot be less than $',
          props.stripeFees['min_transaction_amount']
        );
      }
      else {
        makeDonation(params);
      }
    } else {
      alert('Please select a card');
    }
  }

  return open ? (
    <SimpleBackdrop open={open} />
  ) : (
    <>
      <FormControl component="fieldset">
        <RadioGroup
          defaultValue="no"
          aria-label="choice"
          onChange={(e) => {
            let isPaying = e.target.value === 'yes' ? true : false;
            props.getTotalDonationWithStripeFees(
              isPaying,
              props.selectedAmount
            );
          }}
        >
          <FormControlLabel
            value="yes"
            control={<Radio color="primary" />}
            label="Sure, I'll take care of it."
          />
          <FormControlLabel
            value="no"
            control={<Radio color="primary" />}
            label="No, not at this time."
          />
        </RadioGroup>
      </FormControl>
      <ReCAPTCHA
        sitekey={"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"}
        onChange={(token) => setCaptchaToken(token)}
      />
      <Grid
        container
        justifyContent="space-around"
        style={{ padding: '30px 40px 10px 40px' }}
      >
        <Grid item>
          <Button
            style={{
              textTransform: 'capitalize',
              variant: 'contained',
              backgroundColor: '#0133C0',
              borderRadius: '5px',
              color: '#fff',
              width: '250px',
              fontSize: '18px',
              marginRight: '10px',
            }}
            onClick={() => checkValidation(props.params)}
          >
            Donate {'$' + props.totalDonation}
          </Button>


        </Grid>
      </Grid>
      <div style={{ padding: '10px' }} />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignitems="center"
      >
        <Grid item xs={12}>
          <Typography>
            ${props.donate_amount?.toFixed(2)} of ${props.totalAmount} raised
          </Typography>
        </Grid>

        <div style={{ padding: '20px' }} />
      </Grid>
    </>
  );
}
