import { React, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import AppBar from "@mui/material/AppBar"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { auth, db } from "../../Firebase/Firebase"
import Card from "../Card"
import { Grid } from "@mui/material"

function TabPanel(props) {
  const { children, value, index, classes, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center',
    alignitems: 'center',
    width: "100%",
    backgroundColor: "#FFF",
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
    marginTop: theme.spacing(10),
    [theme.breakpoints.only("xl")]: {
      marginTop: theme.spacing(20),
    },
  },
}))

export default function Menu(props) {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const [filterValue, setFilterValue] = useState(0)
  const refProjects = db.collection("projects")
  const refCauses = db.collection("cause_types")
  const [projects, setProjects] = useState([])
  const [completedprojects, setCompletedProjects] = useState([])
  const userUID = auth().currentUser.uid
  const [causes, setCauses] = useState([]);

  useEffect(() => {
    getCauses()
    getProjects()
    getCompletedProjects()


    // eslint-disable-next-line
  }, [])

  function getCauses() {
    const items = []
    refCauses.onSnapshot((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let causeName = doc.data().cause_display_name
        items.push(causeName)
      });
      setCauses(items)
    })
  }

  async function getProjects() {
    refProjects
      .get()
      .then(async function (querySnapshot) {
        const items = []
        for (var i = 0; i < querySnapshot.docs.length; i++) {
          if (querySnapshot.docs[i].data()["project_status"] == false || querySnapshot.docs[i].data()["project_status"] == 0 || querySnapshot.docs[i].data()["project_status"] == undefined) {
            const projectData = querySnapshot.docs[i].data()
            // console.log(projectData)
            projectData["project_id"] = querySnapshot.docs[i].id
            projectData["favorite"] = await isUserAlreadyLikeProject(
              userUID,
              querySnapshot.docs[i].id
            )
            items.push(projectData)
          }
          items.sort((a, b) => {
            return a.cause_id - b.cause_id
          })
        }
        setProjects(items)
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error)
      })
  }

  function handleLike() {
    getProjects()
  }

  async function isUserAlreadyLikeProject(userUID, project_id) {
    const favProjects = await db
      .collection("favourite_projects")
      .where("project_id", "==", project_id)
      .where("user_id", "==", userUID)
      .get()

    if (favProjects.docs.length > 0) {
      if (favProjects.docs[0].data().favourite) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }


  async function getCompletedProjects() {
    refProjects
      .get()
      .then(async function (querySnapshot) {
        const items = []
        for (var i = 0; i < querySnapshot.docs.length; i++) {
          // console.log(querySnapshot.docs[i].data()["project_status"])
          if (querySnapshot.docs[i].data()["project_status"] == true || querySnapshot.docs[i].data()["project_status"] == 1) {
            const projectData = querySnapshot.docs[i].data()
            projectData["project_id"] = querySnapshot.docs[i].id
            projectData["favorite"] = await isCompletedUserAlreadyLikeProject(
              userUID,
              querySnapshot.docs[i].id
            )
            items.push(projectData)
          }
          items.sort((a, b) => {
            return a.cause_id - b.cause_id
          })
        }
        // console.log(items + 'items')
        setCompletedProjects(items)
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error)
      })
  }

  function handleCompletedLike() {
    getCompletedProjects()
  }


  async function isCompletedUserAlreadyLikeProject(userUID, project_id) {
    const favProjects = await db
      .collection("favourite_projects")
      .where("project_id", "==", project_id)
      .where("user_id", "==", userUID)
      .get()

    if (favProjects.docs.length > 0) {
      if (favProjects.docs[0].data().favourite) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const projectComponent = (projects) => {
    return projects.map((project, index) => (
      <Grid
        // container
        spacing={3}
        xs={12}
        sm={6}
        md={4}
        // style={{ marginBottom: 12, marginTop: 12, marginLeft: 10, marginRight: 10 }}
        key={index}
      >
        <Card
          key={index}
          id={index}
          img={project["project_assets"]["image"]}
          country={project["project_location"]["country"]}
          cause={project["cause_display_name"]}
          name={project["project_name"] ? project["project_name"] :  project["project_charity"]}
          location={project["project_ngo_location"] ? project["project_ngo_location"] : project["project_location"]["country"]}
          description={project["project_description"] ? project["project_description"] : project["project_content"] }
          project_data={project}
          isFavourite={project["favorite"]}
          handleLike={handleLike}
          style={{ marginLeft: 10, marginRight: 10 }}
        />
      </Grid>
    ))
  }

  const projectCompletedComponent = (projects) => {
    return projects.map((project, index) => (
      <Grid
        // container
        spacing={4}
        xs={12}
        sm={6}
        md={4}
        mt={4}
        key={index}
      >
        <Card
          key={index}
          id={index}
          img={project["project_assets"]["image"]}
          country={project["project_location"]["country"]}
          cause={project["cause_display_name"]} 
          location={project["project_ngo_location"] ? project["project_ngo_location"] : project["project_location"]["country"]}
          name={project["project_name"] ? project["project_name"] :  project["project_charity"]}
          description={project["project_description"] ? project["project_description"] : project["project_content"] }
          project_data={project}
          isFavourite={project["favorite"]}
                    handleLike={handleCompletedLike}
          // style={{ marginLeft: 10, marginRight: 10 }}
        />
      </Grid>
    ))
  }


  const causesComponent = causes.map((el, index) => (
    <Tab
      key={index}
      label={el}
      onClick={() => {
        setValue(index + 1)
        setFilterValue(index)
      }}
      style={{ textTransform: "capitalize", fontSize: "16px" }}
    />
  ))

  return (
    <div>
      <div className={classes.root}>
        <h1>CURRENT PROJECT</h1>
        <div style={{ padding: '10px' }} />
        <AppBar
          position="static"
          style={{ color: "#FAFAFA", backgroundColor: "#ffffff" }}
          elevation={0}
        >
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              label="All Causes"
              {...a11yProps(0)}
              style={{ textTransform: "capitalize", fontSize: "16px" }}
              onClick={() => {
                setValue(0)
                setFilterValue(0)
              }}
            />
            {causesComponent}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={value}>
          <Grid container>
            {value === 0 ?
              <Grid container>{projectComponent(projects)}</Grid>
              : projectComponent(projects.filter((el) => el.cause_display_name === causes[filterValue])).length === 0 ?
                <Typography alignItems={"center"} >No project Found</Typography>
                : projectComponent(projects.filter((el) => el.cause_display_name === causes[filterValue]))}
          </Grid>
        </TabPanel>
      </div>
      <div className={classes.root}>
        <h1>COMPLETED PROJECT</h1>
        <div style={{ padding: '10px' }} />
        <AppBar
          position="static"
          style={{ color: "#FAFAFA", backgroundColor: "#ffffff" }}
          elevation={0}
        >
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              label="All Causes"
              {...a11yProps(0)}
              style={{ textTransform: "capitalize", fontSize: "16px" }}
              onClick={() => {
                setValue(0)
                setFilterValue(0)
              }}
            />
            {causesComponent}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={value}>
          <Grid container>
            {value === 0 ?
              <Grid container>{projectCompletedComponent(completedprojects)}</Grid>
              : projectCompletedComponent(completedprojects.filter((el) => el.cause_display_name === causes[filterValue])).length === 0 ?
                <Typography alignItems={"center"} >No project Found</Typography>
                : projectCompletedComponent(completedprojects.filter((el) => el.cause_display_name === causes[filterValue]))}
          </Grid>
        </TabPanel>
      </div>
    </div>
  )
}