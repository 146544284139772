import React from 'react'
import { Grid, Typography } from '@mui/material'

function DonationHead(props) {

    return (
        <React.Fragment>
            <Grid container justifyContent={"center"} style={{ padding: '30px 40px 10px 40px' }}>
                <Grid item>
                    <Typography component="h5" variant="h4">
                        {props.projectName}
                    </Typography>
                </Grid>
            </Grid>
            <div style={{ padding: '10px' }} />
            <Grid container direction="row" justifyContent="space-between" alignitems="center">
                <Grid item xs={12}>
                    <Typography>${props.donate_amount.toFixed(2)} of ${props.totalAmount} raised</Typography>
                </Grid>

                <div style={{ padding: '20px' }} />
            </Grid>
        </React.Fragment>
    )
}

export default DonationHead
