/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";

// @material-ui/icons
import Favorite from "@mui/icons-material/Favorite";

import styles from "../styles/footerStyle";
import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer
      className={footerClasses}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexWrap: "wrap",
        paddingLeft: "24px",
        paddingRight: "24px",
      }}
    >
      {/* <div className={classes.container} style={{ width: "100%" }}> */}
      <Grid container justifyContent={"center"}>
        <Grid item xl={6} sm={12} md={6}>
          <div style={{ width: "100%" }} className={classes.left}>
            <List style={{ display: "flex" }} className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="/"
                  className={classes.block}
                  target="_blank"
                >
                  Caritas Revolution
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link
                  to="/contact-us"
                  className={classes.block}
                // target="_blank"
                >
                  Contact us
                </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a href="/terms" className={classes.block}>
                  Terms & Conditions
                </a>
              </ListItem>
            </List>
          </div>
        </Grid>
        <Grid item xl={6} sm={12} md={6}>
          <p className={classes.right}>
            &copy; {1900 + new Date().getYear()} , made with{" "}
            <Favorite color="primary" className={classes.icon} /> by{" "}
            <a
              href="/"
              className={aClasses}
            // target="_blank"
            >
              Caritas Revolution
            </a>{" "}
            .
          </p>
        </Grid>
      </Grid>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
