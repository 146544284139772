import React from 'react'
import Menu from '../projects/my_projects/Menu'
import Navbar from '../Navbar';


function My_Projects() {
  return (
    <React.Fragment>
      <Navbar />
      <Menu />
    </React.Fragment>
  )
}

export default My_Projects;

