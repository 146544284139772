import React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom"

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default function MediaCard(props) {
  const classes = useStyles();

  return (
    <Link to={"/details?id=" + props.project_data["project_id"]}>
      <Card className={classes.root} style={{ marginBottom: 12 }}>
        <CardActionArea>
          <CardMedia
            component="img"
            alt="Project Raise"
            src={props.image_}
            title="Project Raise"
            className={classes.media}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {props.country}. {props.cause}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {props.name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}
