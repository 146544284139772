import {
  Grid,
  Paper,
  Typography,
  Button,
  // ImageList,
  // ImageListItem,
  // ImageListItemBar,
} from "@mui/material"
import React, { useState, useEffect } from "react"
import { makeStyles } from "@mui/styles"
import Overview from "./Overview"
import ContributedUser from "./ContributedUser"
import { Link } from "react-router-dom"
import { db, auth } from "../../../../Firebase/Firebase"
import FavoriteIcon from "@mui/icons-material/Favorite"
import IconButton from "@mui/material/IconButton"
import DetailsContainer from './DetailsContainer'

import NavProjects from './NavProjects'
import { useLocation } from 'react-router-dom'
import ShareIcon from '@mui/icons-material/IosShare';
import ShareDialog from './ShareDialog'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom"
import pdf_icon from './pdf_icon.png'
// import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(7),
    flexGrow: 1,
    overflow: "hidden",
  },
  app: {
    backgroundColor: "#FFF",
  },
  logo: {
    flexGrow: 1,
    width: "170px",
    height: "180px",
  },
  paper: {
    padding: theme.spacing(4, 3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 4),
    },
  },
  image: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "250px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      height: "400px",
    },
    [theme.breakpoints.only("md")]: {
      width: "100%",
      height: "450px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "80%",
      height: "450px",
    },
  },
  overview: {
    marginTop: theme.spacing(5),
  },
  contributed: {
    marginTop: theme.spacing(-30),
  },
  donate: {
    marginTop: "-20px",
    // backgroundColor: "#E6EFEE",
    width: "100%",
    height: "80px",
  },
  right: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    variant: "contained",
    backgroundColor: "#0133C0",
    width: "120px",
    fontWeight: 'bold',
    borderRadius: "5px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#214DC5",
    },
  },
  link: {
    color: "#000",
    "&:hover": {
      color: "#214DC5",
    },
    fontSize: 18,
    textTransform: "capitalize",
    textDecoration: "none",
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    padding: theme.spacing(4),
  },
  contentpaper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 1,
  },
  end: {
    padding: theme.spacing(4),
  }
}))

let userUID = ""
let projectId = ""
let docId = ""

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Details(props) {
  const classes = useStyles();
  const history = useNavigate();
  const urlBase = 'https://caritasrevolution.com';
  const handleAdd = () => {
    setOpen(true);
    setFormMode(true);
  };
  const handleDone = () => {
    setOpen(false)
  }
  const [ope, setOpen] = useState(false)
  const [formMode, setFormMode] = useState(true)
  const refProjects = db.collection("projects")
  const [projectData, setProjectData] = useState([])
  const refDonations = db.collection("donations")
  const [contributedUser, setContributedUser] = useState([])
  const [favoriteState, setFavoriteState] = useState(true)
  const [contributedState, setContributedState] = useState(false)
  const refFavotiteProjects = db.collection("favourite_projects")
  const [isFavourite, setisFavourite] = useState(false)
  let query = useQuery();

  const [opens, setOpens] = React.useState(false);

  const handleClickOpen = () => {
    setOpens(true);
  };

  const handleClose = () => {
    setOpens(false);
  };

  async function isUserAlreadyLikeProject(userUID, projectId) {
    const favProjects = await db
      .collection("favourite_projects")
      .where("user_id", "==", userUID)
      .where("project_id", "==", projectId)
      .get()

    if (favProjects.docs.length > 0) {
      docId = favProjects.docs[0].id
      setContributedState(favProjects.docs[0].data().contributed)
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    let id = query.get("id")
    projectId = id
    getProjects(id)
    getContributedUsers(id)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // setisFavourite(props.isFavourite)
    userUID = auth().currentUser.uid
  }, [])

  async function getProjects(projectId) {
    refProjects
      .doc(projectId)
      .get()
      .then(async function (querySnapshot) {
        const _project = querySnapshot.data()
        _project["project_id"] = querySnapshot.id
        setisFavourite(isUserAlreadyLikeProject(userUID, projectId))
        setProjectData(_project)
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error)
      })
  }

  async function handleFavorite(event) {
    event.stopPropagation()
    event.preventDefault()
    projectId = projectData["project_id"]
    setFavoriteState(favoriteState)

    if (await isUserAlreadyLikeProject(userUID, projectId)) {
      if (contributedState) {
        refFavotiteProjects.doc(docId).update({
          favourite: !favoriteState,
        })
      } else {
        refFavotiteProjects
          .doc(docId)
          .delete()
          .then(function () {
            console.log("Document successfully deleted!")
          })
          .catch(function (error) {
            console.error("Error removing document: ", error)
          })
      }
    } else {
      refFavotiteProjects
        .add({
          contributed: contributedState,
          favourite: favoriteState,
          project_id: projectId,
          user_id: userUID,
        })
        .then(function (docRef) {
          console.log("Document written with ID: ", docRef.id)
        })
        .catch(function (error) {
          console.error("Error adding document: ", error)
        })
    }
  }

  async function getContributedUsers(projectId) {
    refDonations
      .where("project_id", "==", projectId)
      .orderBy("created_at", "desc")
      .get()
      .then(async function (querySnapshot) {
        const items = []
        for (var i = 0; i < querySnapshot.docs.length; i++) {
          const userName = querySnapshot.docs[i].data()["user_name"]
          items.push(userName)
          // if (items.indexOf(userName) < 0) {
          //   console.log(userName);
          //   items.push(userName)
          // }
          // setContributedUser((passedCUser) => [...passedCUser, userName])
          setContributedUser(items)
        }
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error)
      })
  }

  const contributedComponents = contributedUser.map((user, index) => (
    <ContributedUser
      key={index}
      contributedUser={user}
    />
  ))

  const milestoneComponents = Object.values(
    projectData?.project_milestones || {}
  ).map((milestone, index) => (
    <Typography
      key={index}
      variant="subtitle1"
      color="textSecondary"
      style={{ textAlign: "justify" }}
    >
      {milestone}
    </Typography>
  ))

  function handleButtonClick(projectData) {
    history(`/donation-with-stripe`, { state: { project_data: projectData } });
  }

  function handleButtonClickCrypto(projectData) {
    history(`/donation-with-crypto`, { state: { project_data: projectData } });
  }

  return (
    <>
      <Grid container justifyContent={"center"} paddingLeft={2}>
        <NavProjects />
        <DetailsContainer style={{ display: "flex", justifyContent: "center" }}>
          <Grid item justifyContent="flex-end">
            <IconButton><ShareIcon onClick={handleAdd} style={{ color: "2057c0" }} /></IconButton>
          </Grid>
          <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
            {projectData?.project_name}
          </Typography>
          <Typography variant="h5" align="center" color="textSecondary" paragraph>
            {projectData?.project_location?.country} .{" "}
            {projectData?.cause_display_name}
          </Typography>
          <div className={classes.heroButtons}>
            <Grid container justifyContent={"center"} align="center">
              <Grid item xs={12}>

                <div className={classes.heroButtons}>
                  <Grid container justifyContent={"center"} align="center">
                    <Grid item xs={12}>
                      {
                        (
                          (
                            projectData?.project_assets?.image[0] ?
                              projectData?.project_assets?.image[0].substring(projectData?.project_assets?.image[0].lastIndexOf('.'))
                              : null
                          ) === '.pdf') ?
                          (
                            <div style={{ textAlign: "center" }}>
                              <img src={pdf_icon} style={{ width: "10%", height: "10%" }} color="primary" onClick={(url = projectData?.project_assets?.image[0]) => window.open(projectData?.project_assets?.image[0], '_blank', 'noreferrer')} />
                            </div>
                          ) :
                          (
                            <img
                              src={projectData?.project_assets?.image[0]}
                              alt={projectData?.project_name}
                              style={{ width: "100%", height: "100%" }}
                            />
                          )
                      }
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={classes.cardGrid}>
            <Grid container spacing={4} p={2} >
              <div className={classes.card}>
                <Typography component="h5" variant="h5">
                  Project Goal
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{ textAlign: "justify" }}
                >
                  {projectData?.project_description}
                </Typography>
                <div style={{ padding: "15px" }} />
                <Typography component="h5" variant="h5">
                  NGO and Location
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{ textAlign: "justify" }}
                >
                  {projectData?.project_ngo_location}
                </Typography>
                <div style={{ padding: "15px" }} />
                <Typography component="h5" variant="h5">
                  Project Milestone
                </Typography>
                {milestoneComponents}
                <div style={{ padding: "15px" }} />
              </div>
            </Grid>
            <div style={{ padding: "15px" }} />
            {
              projectData?.project_assets?.youtube_video_id.map((video, index) => (
                video ? (
                  <Grid style={{ border: '1px solid black', padding: '20px', margin: '20px' }} container justifyContent={"center"} align="center" key={index}>
                    <Grid item xs={12}>
                      <Overview
                        url={`https://www.youtube.com/watch?v=${video}`}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  null)
              )
              )
            }
            {
              projectData?.project_assets?.image.map((imagedata, index) => {
                if (index !== 0) {
                  const imageSrc2 = imagedata.substring(imagedata.lastIndexOf('.')).split('?alt')[0];
                  if (imageSrc2 === '.pdf') {
                    return (
                      <div style={{ textAlign: "center" }}>
                        <img src={pdf_icon} style={{ width: "10%", height: "10%" }} color="primary" onClick={(url = imagedata) => window.open(imagedata, '_blank', 'noreferrer')} />
                      </div>
                    );
                  } else {
                    return (
                      <img
                        src={imagedata}
                        alt={projectData?.project_charity}
                        className={classes.image}
                        style={{ width: "100%", height: "100%" }}
                      />
                    );
                  }
                }
              })}
            <div className={classes.content}>
              <Grid container spacing={3}>
                <Grid item xs>
                  <Paper className={classes.contentpaper} elevation={0}>
                    ${projectData?.donated_amount?.toFixed(2)} of ${projectData?.project_funding_goal?.amount} raised
                  </Paper>
                </Grid>
                {
                  projectData?.project_status === true ? '' : (
                    <Grid item xs>
                      <Paper className={classes.contentpaper} elevation={0}>

                        <Button
                          style={{ background: "#0133C0", color: "white", marginTop: '-5px', textTransform: "capitalize" }}
                          className={classes.button}
                          onClick={handleClickOpen}
                        >
                          Donate
                        </Button>

                      </Paper>
                    </Grid>
                  )
                }
                <Grid item xs>
                  <Paper className={classes.contentpaper} elevation={0}>
                    <IconButton
                      aria-label="add to favorites"
                      onClick={handleFavorite}
                      style={{
                        marginTop: '-7px',
                        color: isFavourite ? "red" : "light-grey"
                      }}
                    >
                      <FavoriteIcon />
                    </IconButton>
                  </Paper>
                </Grid>
              </Grid>
            </div>

            <div>
              <Dialog
                open={opens}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Donation Provider"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Which payment provider do you want to use?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => handleButtonClick(projectData)}
                  >
                    Credit card
                  </Button>
                  <Button
                    onClick={() => handleButtonClickCrypto(projectData)}
                    autoFocus>
                    Crypto
                  </Button>
                </DialogActions>
              </Dialog>
            </div>

            <div className={classes.end}>
              <Grid container>
                <Grid item>
                  <Typography style={{ fontWeight: "bold" }}>
                    Who has contributed?
                  </Typography>
                  {contributedComponents}

                </Grid>
              </Grid>
            </div>
          </div>
        </DetailsContainer>
        <ShareDialog
          open={ope}
          close={handleDone}
          formMode={formMode}
          URL={urlBase + 'details?id=' + projectId}
        />
      </Grid>
    </>
  )
}





