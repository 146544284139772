import React, { useState, useEffect } from 'react';
import Markdown from 'markdown-to-jsx';
import mdDocument from '../modules/views/privacy.md';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '../modules/styles/Typography';

const PrivacyPolicies = () => {
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch(mdDocument)
      .then((res) => res.text())
      .then((md) => {
        setContent(md);
      });
  });

  return (
    <React.Fragment>
      <Container>
        <Box mt={10} mb={12}>
          <Typography variant="h4" gutterBottom marked="center" align="center">
            {/* CARITAS REVOLUTION PRIVACY POLICIES */}
            ONLINE PRIVACY POLICY AGREEMENT
          </Typography>
          <div style={{ padding: '20px' }} />
          <Container maxWidth="md">
            <Markdown
              style={{ fontFamily: 'Verdana', textAlign: 'justify' }}
              textAlign="justify"
              children={content}
            />
          </Container>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default PrivacyPolicies;
