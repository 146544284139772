
import * as actions from './actionTypes';
import { messaging } from '../../Firebase/Firebase'
import axios from 'axios';
import { salesforceBackURL } from '../../../src/firebaseSecrets';


// Sign up action creator
export const userSignupSalesforce =
  (data) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;

    //Token
    console.log(data, "data");
    const _fcm_token = await messaging.getToken();
    console.log(data, "data", user, "user", _fcm_token);
    await firestore
      .collection("users")
      .doc(user.uid)
      .set({
        user_name: data.profile.displayName,
        firstName: data.additionalUserInfo.profile.given_name,
        lastName: data.additionalUserInfo.profile.family_name,
        user_email: data.profile.email,
        phone_number: "",
        country: "",
        cause: "",
        fcm_token: {
          token: _fcm_token,
          platform: "Web",
        },
        // fcm_tokens: _fcm_token,
      });

    let query_newCustomer = await firestore
      .collection("users")
      .where("user_email", "==", data.profile.email)
      .get();
    // console.log(query_newCustomer, "queryNewjff");
    console.log('customer',query_newCustomer);
    if (query_newCustomer.docs.length > 0) {
      if (
        query_newCustomer.docs[0].data() !== null &&
        query_newCustomer.docs[0].data() !== undefined
      ) {
        let newCustomer = query_newCustomer.docs[0].data();
        console.log('newCustomer',newCustomer);
        newCustomer["id"] = query_newCustomer.docs[0].id;
        await axios.post(
          salesforceBackURL + "addNewUser",
          JSON.parse(JSON.stringify(newCustomer))
        );
      }
    }
  };

export const signUp =
(data) =>
async (dispatch, getState, { getFirebase, getFirestore }) => {
  const firebase = getFirebase();
  const firestore = getFirestore();

  dispatch({ type: actions.AUTH_START });

  try {
    let response = await firebase
      .auth()
      .createUserWithEmailAndPassword(data.email, data.password)
      .then((response) => {
        sendEmailVerification();
      })
      .catch((error) => {
        console.log(error);
        // dispatch({ type: actions.AUTH_FAIL, payload: "There is no user record corresponding to this identifier. The user may have been deleted." });
      });

    async function sendEmailVerification() {
      const user = firebase.auth().currentUser;

      await user
        .sendEmailVerification({ url: "https://caritasrevolution.com/login" })
        .then(() => {
          try {
            localStorage.setItem("Name", data.email);
            localStorage.setItem("Password", data.password);
            dispatch({ type: actions.VERIFY_SUCCESS });
          } catch (err) {
            dispatch({ type: actions.AUTH_FAIL, payload: err.message });
          }
        })
        .catch((error) => {
          dispatch({ type: actions.AUTH_FAIL, payload: error.message });
        });
    }

    // Send the verfication email
    const user = firebase.auth().currentUser;

    //Token
    const _fcm_token = await messaging.getToken();
    // console.log(data, "data", user, "user", _fcm_token);
    await firestore
      .collection("users")
      .doc(user.uid)
      .set({
        user_name: data.firstName + "  " + data.lastName,
        firstName: data.firstName,
        lastName: data.lastName,
        user_email: data.email,
        phone_number: data.phone,
        country: data.country,
        cause: "",
        fcm_token: {
          token: _fcm_token,
          platform: "Web",
        },
        // fcm_tokens: _fcm_token,
      });

    // salesforce function
    let query_newCustomer = await firestore
      .collection("users")
      .where("user_email", "==", data.email)
      .get();
    // console.log(query_newCustomer, "queryNewjff");
    if (query_newCustomer.docs.length > 0) {
      if (
        query_newCustomer.docs[0].data() !== null &&
        query_newCustomer.docs[0].data() !== undefined
      ) {
        let newCustomer = query_newCustomer.docs[0].data();

        newCustomer["id"] = query_newCustomer.docs[0].id;
        await axios.post(
          salesforceBackURL + "addNewUser",
          JSON.parse(JSON.stringify(newCustomer))
        );
      }
    }
    dispatch({ type: actions.AUTH_SUCCESS });
  } catch (err) {
    console.log(err, "error");
    dispatch({
      type: actions.AUTH_FAIL,
      payload: "Something went wrong. Please try again.",
    });
  }
  dispatch({ type: actions.AUTH_END });
};


// Logout action creator
export const signOut = () => async (dispatch, getState, { getFirebase }) => {

  const firebase = getFirebase();
  try {
    await firebase.auth().signOut();
    // history('/login');
  } catch (err) {
    console.log(err.message);
  }
};

// Login action creator
export const signIn = data => async (dispatch, getState, { getFirebase }) => {
  dispatch({ type: actions.AUTH_START });
  try {
    const firebase = getFirebase();
    firebase.auth().signInWithEmailAndPassword(data.email, data.password).then((userCredential) => {
      // Signed in

      const user = userCredential.user;

      if (user.emailVerified) {

        return dispatch({ type: actions.AUTH_SUCCESS });
      }
      else {
        return dispatch({ type: actions.AUTH_FAIL, payload: "Please verify your email address" });
      }

    }).catch((error) => {
      return dispatch({ type: actions.AUTH_FAIL, payload: "Invalid login credentials" });
    }
    );
  }
  catch (err) {
    return dispatch({ type: actions.AUTH_FAIL, payload: "Invalid login credentials" });
  }
  dispatch({ type: actions.AUTH_END });
};

// ----------------
export const getSignInCredentials = data => async (dispatch, getState, { getFirebase }) => {
  const firebase = getFirebase();
  // dispatch({ type: actions.AUTH_START });
  try {
    let res = await firebase.auth().signInWithEmailAndPassword(data.email, data.password) //firebase.auth().signInWithEmailAndPassword(data.email, data.password);
    res["quick_donation"] = true
    // dispatch({ type: actions.AUTH_SUCCESS });
    dispatch({ type: actions.IS_QUICK_DONATION });
    return res;
  }
  catch (err) {
    dispatch({ type: actions.IS_NOT_QUICK_DONATION });
  }
  dispatch({ type: actions.QUICK_DONATION_END });
};

// Clean up messages
export const clean = () => ({
  type: actions.CLEAN_UP,
});

// export const isQuickDonation = () => ({
//   type: actions.IS_QUICK_DONATION,
// });

// Verify email actionTypes
export const verifyEmail = () => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  dispatch({ type: actions.VERIFY_START });
  try {
    const user = firebase.auth().currentUser;
    await user.sendEmailVerification();
    dispatch({ type: actions.VERIFY_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.VERIFY_FAIL, payload: err.message });
  }
};


// Send recover password
export const recoverPassword = data => async (dispatch, getState, { getFirebase }) => {
  const firebase = getFirebase();
  dispatch({ type: actions.RECOVERY_START });
  try {
    // send email here
    await firebase.auth().sendPasswordResetEmail(data.email, { url: "https://caritasrevolution.com/login" });
    dispatch({ type: actions.RECOVERY_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.RECOVERY_FAIL, payload: err.message });
  }
};

// Edit profile
export const editProfile =
  (data) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser;

    const { uid: userId, email: userEmail } = getState().firebase.auth;
    dispatch({ type: actions.PROFILE_EDIT_START });

    try {
      //edit the user profile
      if (data.email !== userEmail) {
        await user.updateEmail(data.email);
      }
      //Token
      const _fcm_token = await messaging.getToken();

      await firestore
        .collection("users")
        .doc(userId)
        .set({
          user_name: data.firstName + "  " + data.lastName,
          firstName: data.firstName,
          lastName: data.lastName,
          user_email: data.email,
          phone_number: data.phone_number,
          country: data.country,
          cause: [],
          fcm_token: {
            token: _fcm_token,
            platform: "Web",
          },
        });

      if (data.password.length > 0) {
        await user.updatePassword(data.password);
      }
      let newData = {
        user_name: data.firstName + "  " + data.lastName,
        firstName: data.firstName,
        lastName: data.lastName,
        user_email: data.email,
        phone_number: data.phone_number,
        country: data.country,
        cause: [],
        fcm_token: {
          token: _fcm_token,
          platform: "Web",
        },
      };
      let updateUserData = {
        id: userId,
        data: newData,
      };
      await axios.post(salesforceBackURL + "updateCustomer", updateUserData);

      dispatch({ type: actions.PROFILE_EDIT_SUCCESS });
    } catch (err) {
      dispatch({ type: actions.PROFILE_EDIT_FAIL, payload: err.message });
    }
  };

// Delete user
export const deleteUser = () => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();
  const user = firebase.auth().currentUser;
  const userId = getState().firebase.auth.uid;
  // console.log("------->" + userId)
  dispatch({ type: actions.DELETE_START });
  try {
    await firestore
      .collection('users').doc(userId).delete();


    await user.delete();
  } catch (err) {
    dispatch({ type: actions.DELETE_FAIL, payload: err.message });
  }
};