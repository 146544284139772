/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import CreditCards from './CreditCards';
import 'react-credit-cards/es/styles-compiled.css';
import { Grid } from '@mui/material';
import { auth, db } from '../../Firebase/Firebase';
import AddCard from './Form/addcreditCard';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Typography from '@mui/material/Typography';
import { myCollection } from '../../Firebase/Firebase';


function Main(props) {

  // firebase collection to get stripe key

  const [open, setOpen] = useState(false);
  const [formMode, setFormMode] = useState(true);
  const [close, setClose] = useState(true);
  const [card, setCard] = useState([]);
  const [StripePublishedKey, setStripePublishedKey] = useState('');
  
     
  

  const userUID = auth().currentUser.uid;

  useEffect(() => {
    // fetchData(); 
    getUserCard();
  });

  // const fetchData = async () => {
  //   const snapshot = await myCollection.get();
  //   const docs = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  //   setStripePublishedKey(docs[0]['STRIPE_Publishable_Key']);
  // };
  
  const stripePromise = loadStripe('pk_live_Nic47fewQySP2YIUPIKM4JnO00UiCRcbIE');   


  const handleClose = () => {
    setOpen(false);
    setFormMode(false);
    setClose(true);
  };

   const getUserCard = async () => {
    var items = [];
    const cardRef = await db.collection('users/' + userUID + '/cards').get();
    cardRef.forEach((card) => {
      items.push(card.data());
    });
    setCard(items);
  }

  const deleteCard = async (id) => {
    const cardRef = db.collection('users/' + userUID + '/cards');
    const card = await cardRef.doc(id).delete();
    getUserCard();
  };


  const cardComponents = card.map((card, index) => (
    <Grid container justifyContent={"center"}>
      <Grid item>
        <CreditCards
          deleteCard={(id) => deleteCard(id)}
          handleClose={() => handleClose()}
          index={index}
          card={card}
        />
      </Grid>
    </Grid>
  ));

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Elements stripe={stripePromise}>
          <AddCard
            close={props.close}
            paymentId={props.paymentId}
            handleNewCard={props.handleNewCard}
            addCardWIthoutSaving={props.addCardWIthoutSaving}
          />
        </Elements>
        <div style={{ padding: '10px' }} />
        <Grid item xs={12}>
          <Typography>
            <strong>Current Card(s)</strong>
          </Typography>
        </Grid>
        {cardComponents}
      </Grid>
    </Grid>
  );
}

export default Main;
