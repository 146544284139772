import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NavProjects from './NavProjects';
import DetailsContainer from './DetailsContainer';
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from 'recharts';
import { db } from '../../../../Firebase/Firebase';
import OverViewCard from './OverviewCard';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(7),
    flexGrow: 1,
    overflow: 'hidden',
  },
  app: {
    backgroundColor: '#FFF',
  },
  logo: {
    flexGrow: 1,
    width: '170px',
    height: '180px',
  },
  paper: {
    padding: theme.spacing(4, 3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 4),
    },
  },
  image: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '250px',
    },
    [theme.breakpoints.only('sm')]: {
      width: '100%',
      height: '400px',
    },
    [theme.breakpoints.only('md')]: {
      width: '100%',
      height: '450px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '80%',
      height: '450px',
    },
  },
  overview: {
    marginTop: theme.spacing(5),
  },
  contributed: {
    marginTop: theme.spacing(-30),
  },
  donate: {
    marginTop: '-20px',
    // backgroundColor: "#E6EFEE",
    width: '100%',
    height: '80px',
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    variant: 'contained',
    backgroundColor: '#0133C0',
    width: '120px',
    fontWeight: 'bold',
    borderRadius: '5px',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#214DC5',
    },
  },
  link: {
    color: '#000',
    '&:hover': {
      color: '#214DC5',
    },
    fontSize: 18,
    textTransform: 'capitalize',
    textDecoration: 'none',
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    padding: theme.spacing(4),
  },
  contentpaper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 1,
  },
  end: {
    padding: theme.spacing(4),
  },
}));

const TotalOverview = () => {
  const [totalDonation, setTotalDonation] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const refDonations = db.collection('donations');

  async function getTotalDonation() {
    let amount = 0;
    try {
      const donationsSnapshots = await refDonations
        .orderBy('created_at', 'desc')
        .get();

      donationsSnapshots.forEach((d) => {
        amount += d.data()?.amount;
      });
    } catch (error) {
      console.log('Error getting documents: ', error);
    }
    return amount;
  }

  async function getTotalDonationInUSD() {
    let amount = 0;
    try {
      const donationsSnapshots = await refDonations
        .where('type', '!=', 7)
        .orderBy('type', 'asc')
        .get();

      donationsSnapshots.forEach((d) => {
        amount += d.data()?.amount;
      });
    } catch (error) {
      console.log('Error getting documents: ', error);
    }
    return amount;
  }

  async function getTotalDonationInCrypto() {
    let amount = 0;
    try {
      const donationsSnapshots = await refDonations
        .where('type', '==', 7)
        .orderBy('created_at', 'desc')
        .get();

      donationsSnapshots.forEach((d) => {
        amount += d.data()?.amount;
      });
    } catch (error) {
      console.log('Error getting documents: ', error);
    }
    return amount;
  }

  const addDonationToChart = async (total) => {
    setTotalDonation((don) => [...don, total]);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    let tot = await getTotalDonation();
    setTotalAmount(tot);

    let totalInUSD = {
      name: 'USD',
      amount: await getTotalDonationInUSD(),
    };

    addDonationToChart(totalInUSD);

    let totalInCrypto = {
      name: 'Crypto',
      amount: await getTotalDonationInCrypto(),
    };

    addDonationToChart(totalInCrypto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NavProjects />
      <Grid container justifyContent="center">
        <DetailsContainer >
          <OverViewCard total={`${totalAmount}$`} />

          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Total Overview
            Total Overview
          </Typography>

          <BarChart
            width={window.innerWidth / 1.7}
            height={250}
            data={totalDonation}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="amount" fill="#2057C0" />
          </BarChart>
        </DetailsContainer>
      </Grid>
    </>
  );
};

export default TotalOverview;
