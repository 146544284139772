import React, { useState } from 'react';
import {
  Grid,
  Box,
  Modal,
  TextField,
  Paper,
  FormControl,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import DialogCrypto from '../../../pages/projects/my_projects/details/DialogCryptoNoProject';
import AddProfileCard from './AddProfileCard';
import LoginModal from './LoginModal';
import AuthAlert from './AuthAlert';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  app: {
    backgroundColor: '#fff',
    // [theme.breakpoints.down('xs')]: {
    //   backgroundColor: '#FFF',
    // },
  },

  logo: {
    flexGrow: 1,
    width: '170px',
    height: '180px',
  },
  img: {
    // [theme.breakpoints.only('xs')]: {
    //   width: '130px',
    // },
    // [theme.breakpoints.only('sm')]: {
    //   width: '150px',
    // },
  },
  projects: {
    color: '#0133C0',
    '&:hover': {
      color: '#214DC5',
    },
    fontSize: 16,
    textTransform: 'capitalize',
  },
  login: {
    color: '#0133C0',
    '&:hover': {
      color: '#214DC5',
    },
    fontSize: 16,
    textTransform: 'capitalize',
    // marginLeft: theme.spacing(3),
    backgroundColor: 'none',
    // [theme.breakpoints.down('xs')]: {
    //   fontSize: '15px',
    //   paddingRight: '40px',
    // },
  },
  signup: {
    // marginLeft: theme.spacing(2),
    fontSize: 16,
    textTransform: 'capitalize',
    variant: 'contained',
    backgroundColor: '#0133C0',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#214DC5',
    },
    // [theme.breakpoints.down('xs')]: {
    //   fontSize: '14px',
    //   overflow: 'hidden',
    //   marginLeft: theme.spacing(3),
    //   backgroundColor: 'none',
    // },
  },
  nav: {
    display: 'flex',
    // [theme.breakpoints.down('xs')]: {
    //   display: 'none',
    // },
  },
  sectionMobile: {
    display: 'flex',
    // [theme.breakpoints.up('sm')]: {
    //   display: 'none',
    // },
  },
  moreIcon: {
    // [theme.breakpoints.only('xs')]: {
    //   color: 'gray',
    // },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      // className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const QuickDonationComponentWithCrypto = ({
  openQuickDonationCrypto,
  handleCloseQuickDonationCrypto,
}) => {
  const classes = useStyles();
  const [selectedAmount, setSelectedAmount] = useState('$5.00');
  const [totalDonation, setTotalDonation] = useState('5.00');
  const [isLoginSelected, setIsLoginSelected] = useState(false);
  const [openProfileCard, setOpenProfileCard] = React.useState(false);
  const [captchaToken, setCaptchaToken] = useState('');

  const handleOpenProfileCard = () => setOpenProfileCard(true);
  const handleCloseProfileCard = () => {
    setOpenProfileCard(false);
    donateWithCrypto(_params);
  };

  const [openAuthAlert, setOpenAuthAlert] = useState(false);
  const handleOpenAuthAlert = () => setOpenAuthAlert(true);
  const handleCloseAuthAlert = () => {
    setOpenAuthAlert(false);
  };

  const [openBackdrop, setOpenBackdrop] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(false);

  const _params = {
    project_id: '',
    project_name: 'QUICK DONATION',
    project_description:
      'THIS PROCESS CONSIST ON DONATE IN LESS THAN ONE MINUTE',
    amount: selectedAmount.toString().substr(1),
    user_name: '',
    user_email: '',
    unique_id: localStorage.getItem('unique'),
    donated_amount: selectedAmount,
  };

  const [openLoginCard, setOpenLoginCard] = useState(false);
  const handleOpenLoginCard = () => setOpenLoginCard(true);
  const handleCloseLoginCard = () => {
    setOpenLoginCard(false);
    donateWithCrypto(_params);
  };

  function getSelectedAmount(e) {
    e.preventDefault();
    let amount =
      typeof e.target.value == 'undefined' ? selectedAmount : e.target.value;

    setSelectedAmount(amount);
    _params['amount'] = e.target.value.toString().substr(1);
    setTotalDonation(_params['amount']);
  }

  function handleCustomAmount(e) {
    e.preventDefault();
    setSelectedAmount(e.target.value);
  }

  function donateWithCrypto() {
    if(captchaToken === ''){
      alert('Please verify captcha');
      setOpenBackdrop(false);
      return;
    }
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };

    if (_params['user_email'] === '' || _params['user_name'] === '') {
      handleOpenAuthAlert();
    } else {
      setOpenBackdrop(true);
      axios
        .post(
          'https://us-central1-caritas-revolution-438c3.cloudfunctions.net/createCharge',
          _params,
          {
            headers: headers,
          }
        )
        .then((response) => {
          let d = response.data.data;
          setOpen(false);
          window.open(
            d.hosted_url,
            '_blank' // <- This is what makes it open in a new window.
          );
          setOpenBackdrop(false);
        })
        .catch((error) => {
          console.log('Error is', error);
        });
    }
  }

  return (
    <div>
      <Modal
        open={openQuickDonationCrypto}
        onClose={handleCloseQuickDonationCrypto}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #2057C0',
            boxShadow: 24,
            p: 4,
            justifyContent: 'center',
            alignitems: 'center',
            alignContent: 'center',
          }}
        >
          <Paper elevation={0}>
            <Paper className={classes.paper} elevation={0}>
              <FormControl component="fieldset" style={{ width: '100%' }}>
                <Grid
                  container
                  style={{ width: '100%', justifyContent: 'center' }}
                >
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ justifyContent: 'center' }}
                  >
                    <Typography
                      style={{ fontWeight: 'bold', fontSize: '18px' }}
                    >
                      Donate with crypto
                    </Typography>
                  </Grid>
                  <div style={{ padding: '10px' }} />
                  <Grid
                    item
                    xs={12}
                    style={{ width: '100%', justifyContent: 'center' }}
                  >
                    <RadioGroup
                      defaultValue="$5.0"
                      aria-label="donation"
                      name="customized-radios"
                      onChange={getSelectedAmount}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ justifyContent: 'flex-start' }}
                      >
                        <FormControlLabel
                          value="$5.0"
                          control={<StyledRadio />}
                          label="$5.0"
                        />
                      </Button>

                      <div style={{ padding: '6px' }} />

                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ justifyContent: 'flex-start' }}
                      >
                        <FormControlLabel
                          value="$10.0"
                          control={<StyledRadio />}
                          label="$10.0"
                        />
                      </Button>

                      <div style={{ padding: '6px' }} />

                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ justifyContent: 'flex-start' }}
                      >
                        <FormControlLabel
                          value="$20.0"
                          control={<StyledRadio />}
                          label="$20.0"
                        />
                      </Button>
                      <div style={{ padding: '6px' }} />
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ width: '100%', justifyContent: 'flex-start' }}
                      >
                        <FormControlLabel
                          value={selectedAmount}
                          control={<StyledRadio />}
                          label={
                            <TextField
                              id="standard-bare"
                              defaultValue="$0"
                              size="small"
                              InputProps={{ disableUnderline: true }}
                              onChange={handleCustomAmount}
                            />
                          }
                        />
                      </Button>
                    </RadioGroup>
                  </Grid>
                </Grid>
              </FormControl>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={(token) => setCaptchaToken(token)}
              />
              <Grid container>
                <DialogCrypto
                  selectAmount={selectedAmount}
                  totalDonation={totalDonation}
                  handleOpenProfileCard={handleOpenProfileCard}
                  openBackdrop={openBackdrop}
                  donateWithCrypto={donateWithCrypto}
                />

                <AddProfileCard
                  _params={_params}
                  openProfileCard={openProfileCard}
                  handleCloseProfileCard={handleCloseProfileCard}
                  handleOpenLoginCard={handleOpenLoginCard}
                  isLoginSelected={isLoginSelected}
                  setIsLoginSelected={setIsLoginSelected}
                />

                <AuthAlert
                  openAuthAlert={openAuthAlert}
                  handleCloseAuthAlert={handleCloseAuthAlert}
                  handleOpenProfileCard={handleOpenProfileCard}
                  handleOpenLoginCard={handleOpenLoginCard}
                />

                <LoginModal
                  _params={_params}
                  openLoginCard={openLoginCard}
                  handleOpenLoginCard={handleOpenLoginCard}
                  handleCloseLoginCard={handleCloseLoginCard}
                />
              </Grid>
            </Paper>
          </Paper>
        </Box>
      </Modal>
    </div>
  );
};
export default QuickDonationComponentWithCrypto;
