
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { signInWithPopup, FacebookAuthProvider, GoogleAuthProvider, getAuth } from "firebase/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
import "firebase/compat/messaging";
import "firebase/compat/database";
import { firebaseSecrets } from '../firebaseSecrets.js'


const config = {
  apiKey: firebaseSecrets.apiKey,
  authDomain: firebaseSecrets.authDomain,
  databaseURL: firebaseSecrets.databaseURL,
  projectId: firebaseSecrets.projectId,
  storageBucket: firebaseSecrets.storageBucket,
  messagingSenderId: firebaseSecrets.messagingSenderId,
  appId: firebaseSecrets.appId,
};


firebase.initializeApp(config);

firebase.firestore();
export const db = firebase.firestore();
export const database = firebase.database();
// export default firebase;

const storage = firebase.storage();
export const auth = firebase.auth;
export const cloudFunctions = firebase.functions();
export const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;

export const myCollection = db.collection("stripe_keys");


const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  promt: "select_account",
});

const google = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => auth().signInWithPopup(google);


export { storage, firebase as default };
