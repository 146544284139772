import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

export default function AuthAlert({ openAuthAlert, handleCloseAuthAlert, handleOpenProfileCard, handleOpenLoginCard }) {


  return <div>
    <div>
      <Dialog
        open={openAuthAlert}
        onClose={handleCloseAuthAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"AUTH CHOOSER"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please choose to provide your name and email or login if you already have an account
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCloseAuthAlert()
              handleOpenProfileCard()
            }}
          >
            Provide Name and Email
          </Button>
          <Button
            onClick={() => {
              handleCloseAuthAlert()
              handleOpenLoginCard()
            }}
          >
            Login Instead
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  </div>
}