import React, { useState } from 'react';
import { Grid, Box, Modal, Paper, Typography, TextField, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  app: {
    backgroundColor: '#fff',
    [theme.breakpoints.down("xs")]: {
      backgroundColor: '#FFF',
    },
  },

  logo: {
    flexGrow: 1,
    width: "170px",
    height: '180px'
  },
  img: {
    [theme.breakpoints.only("xs")]: {
      width: '130px',
    },
    [theme.breakpoints.only("sm")]: {
      width: '150px',
    },
  },
  projects: {
    color: "#0133C0",
    "&:hover": {
      color: "#214DC5",
    },
    fontSize: 16,
    textTransform: 'capitalize',
  },
  login: {
    color: "#0133C0",
    "&:hover": {
      color: "#214DC5",
    },
    fontSize: 16,
    textTransform: 'capitalize',
    marginLeft: theme.spacing(3),
    backgroundColor: 'none',
    [theme.breakpoints.down("xs")]: {
      fontSize: '15px',
      paddingRight: '40px'
    },
  },
  signup: {
    marginLeft: theme.spacing(2),
    fontSize: 16,
    textTransform: 'capitalize',
    variant: "contained",
    backgroundColor: "#0133C0",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#214DC5"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: '14px',
      overflow: "hidden",
      marginLeft: theme.spacing(3),
      backgroundColor: 'none',
    },
  },
  nav: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  moreIcon: {
    [theme.breakpoints.only('xs')]: {
      color: 'gray'
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
}));

const AddProfileCard = ({ openProfileCard, handleCloseProfileCard, handleOpenLoginCard, _params }) => {

  const [userName, setUserName] = useState('')
  const [userEmail, setUserEmail] = useState('')

  const classes = useStyles();

  _params["user_name"] = userName
  _params["user_email"] = userEmail

  return (
    <div>
      <Modal
        open={openProfileCard}
        onClose={handleCloseProfileCard}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '30%',
          bgcolor: 'background.paper',
          border: '2px solid #2057C0',
          boxShadow: 24,
          p: 1,
          justifyContent: 'center',
        }}>
          <Paper elevation={0}>
            <Paper
              className={classes.paper}
              elevation={0}
            >
              <Grid container>
                <div padding="5px" />
                <Grid item container xs={10} lg={8} md={6}>
                  <Typography style={{ fontWeight: 'bold', fontSize: '18px' }}>Name and Email</Typography>
                </Grid>

                <Grid item container xs={12} lg={12} md={12}>
                  <TextField id="outlined-basic-name" required label="Please your Name here!" variant="outlined" style={{ width: '100%' }}
                    onChange={(e) => {
                      setUserName(e.target.value)
                    }}
                  />
                </Grid>
                <div style={{ padding: '5px' }} />
                <Grid item container xs={12} lg={12} md={12}>
                  <TextField id="outlined-basic-email" required label="Please your Email here!" variant="outlined" style={{ width: '100%' }}
                    onChange={(e) => {
                      setUserEmail(e.target.value)
                    }}

                  />
                </Grid>
                <div style={{ padding: '5px' }} />
                <Grid item container style={{ justifyContent: 'right' }}>
                  <Button
                    style={{
                      textTransform: "capitalize",
                      variant: "contained",
                      backgroundColor: "#0133C0",
                      borderRadius: '5px',
                      color: "#fff",
                      width: '100px',
                      fontSize: '18px',
                      marginRight: '10px',
                    }}
                    onClick={() => {
                      handleCloseProfileCard()
                    }
                    }
                  >
                    Done
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Paper>
        </Box>
      </Modal>
    </div >
  )
}

export default AddProfileCard