import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",

    [theme.breakpoints.only("xs")]: {
      marginTop: "-280px",
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: "-500px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      marginTop: "-90px",
    },
    [theme.breakpoints.only("xl")]: {
      marginTop: "-300px",
    },
  },
  text: {
    textAlign: "center",
    margin: "5px",
    [theme.breakpoints.only("xs")]: {
      textAlign: "justify",
      marginTop: "20px",
      fontFamily: "Poppins",
    },
  },
  shapeColor: {
    backgroundColor: "#2057C0",
    borderRadius: "50px",
    marginTop: "100px",
    borderTopWidth: 2,
    borderBottomWidth: 2,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderColor: "grey",
    borderStyle: "solid",
  },
}));

export default function Reality() {
  const classes = useStyles();

  return (
    <div className={`animate__animated animate__fadeInLeft ${classes.root}`} style={{ marginTop: '0rem' }}>
      <br />
      <Grid container justifyContent={"center"}>
        <Grid
          item
          xs={10}
          sm={10}
          md={10}
          lg={10}
          xl={10}
          align="left"
          style={{
            padding: "2.5rem",
            paddingTop: "1.7rem",
            backgroundColor: "#fff",
            boxShadow: "rgb(206 206 206) 0px 0px 10px",
            borderRadius: "10px",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <br />
          <Typography
            variant="h2"
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "32px",
              color: "#000",
            }}
          >
            THE SAD TRUTH
          </Typography>
          <Typography
            style={{
              fontStyle: "normal",
              fontSize: "16px",
              marginTop: "20px",
              fontFamily: "Poppins",
              color: "#000",
            }}
          >
            While many of us would love to support charities across the globe,
            we often don’t for lack of transparency: How will our money be used?
            How do we know we’re not being scammed?
          </Typography>
          <Typography
            style={{
              fontStyle: "normal",
              fontSize: "16px",
              marginTop: "20px",
              fontFamily: "Poppins",
              color: "#000",
            }}
          >
            There are countless charities that desperately need funds for local
            projects crucial to their communities, but lack the resources to get
            their projects out there. And there’s people like us who would love
            to be a part of this important work, but have no idea how our
            support will actually be used.
          </Typography>
          <Typography
            style={{
              fontStyle: "normal",
              fontSize: "16px",
              marginTop: "20px",
              fontFamily: "Poppins",
              color: "#000",
            }}
          >
            That’s where Caritas Revolution comes in.
          </Typography>
          <br />
        </Grid>
      </Grid>
    </div>
  );
}
