import {
  Grid,
  Paper,
  Typography,
  Button,
} from "@mui/material"
import React, { useState, useEffect } from "react"
import { makeStyles } from "@mui/styles"
import Overview from "./Overview"
import { Link } from "react-router-dom"
import { db } from "../../../../Firebase/Firebase"
import FavoriteIcon from "@mui/icons-material/Favorite"
import IconButton from "@mui/material/IconButton/IconButton"
import DetailsContainer from './DetailsContainer'
import NavProjects from './NavProjects'
import { useLocation } from 'react-router-dom'
import ShareIcon from '@mui/icons-material/Share';
import ShareDialog from './ShareDialog'
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import pdf_icon from './pdf_icon.png'

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

const MyDocument = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>Section #1</Text>
      </View>
      <View style={styles.section}>
        <Text>Section #2</Text>
      </View>
    </Page>
  </Document>
);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(7),
    flexGrow: 1,
    overflow: "hidden",
  },
  app: {
    backgroundColor: "#FFF",
  },
  logo: {
    flexGrow: 1,
    width: "170px",
    height: "180px",
  },
  paper: {
    padding: theme.spacing(4, 3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 4),
    },
  },
  image: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "250px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      height: "400px",
    },
    [theme.breakpoints.only("md")]: {
      width: "100%",
      height: "450px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "80%",
      height: "450px",
    },
  },
  overview: {
    marginTop: theme.spacing(5),
  },
  contributed: {
    marginTop: theme.spacing(-30),
  },
  donate: {
    marginTop: "-20px",
    // backgroundColor: "#E6EFEE",
    width: "100%",
    height: "80px",
  },
  right: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    variant: "contained",
    backgroundColor: "#0133C0",
    width: "120px",
    fontWeight: 'bold',
    borderRadius: "5px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#214DC5",
    },
  },
  link: {
    color: "#000",
    "&:hover": {
      color: "#214DC5",
    },
    fontSize: 18,
    textTransform: "capitalize",
    textDecoration: "none",
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    padding: theme.spacing(4),
  },
  contentpaper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 1,
  },
  end: {
    padding: theme.spacing(4),
  }
}))

let projectId = ""

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function DetailsFree(props) {
  const classes = useStyles()
  const urlBase = 'https://caritasrevolution.com';

  const handleAdd = () => {
    setOpen(true);
    setFormMode(true);
  };
  const handleDone = () => {
    setOpen(false)
  }
  const [open, setOpen] = useState(false)
  const [formMode, setFormMode] = useState(true)

  const refProjects = db.collection("projects")
  const [projectData, setProjectData] = useState([])
  const [isFavourite] = useState(false)
  let query = useQuery();


  useEffect(() => {
    let id = query.get("id")
    projectId = id
    getProjects(id)
    // eslint-disable-next-line
  }, [])

  async function getProjects(projectId) {
    refProjects
      .doc(projectId)
      .get()
      .then(async function (querySnapshot) {
        // console.log(querySnapshot.data())
        const _project = querySnapshot.data()
        _project["project_id"] = querySnapshot.id
        setProjectData(_project)
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error)
      })
  }

  const milestoneComponents = Object.values(
    projectData?.project_milestones || {}
  ).map((milestone, index) => (
    <Typography
      key={index}
      variant="subtitle1"
      color="textSecondary"
      style={{ textAlign: "justify" }}
    >
      {milestone}
    </Typography>
  ))

  console.log(JSON.stringify(projectData) + "projectData")

  return (
    <>
      <Grid container justifyContent={"Center"}>
        <DetailsContainer>
          <Grid container justifyContent="flex-end">
            <IconButton><ShareIcon onClick={handleAdd} style={{ color: "2057c0" }} /></IconButton>
          </Grid>
          <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
            {projectData?.project_name}
          </Typography>
          <Typography variant="h5" align="center" color="textSecondary" paragraph>
            {projectData?.project_location?.country}
            {projectData?.cause_display_name}
          </Typography>
          <div className={classes.heroButtons}>
            <Grid container justifyContent={"center"} align="center">
              <Grid item xs={12}>
                {
                  (
                    (
                      projectData?.project_assets?.image[0] ?
                        projectData?.project_assets?.image[0].substring(projectData?.project_assets?.image[0].lastIndexOf('.'))
                        : null
                    ) === '.pdf') ?
                    (
                      <div style={{ textAlign: "center" }}>
                        <img src={pdf_icon} style={{ height: '10%', width: '10%' }} color="primary" onClick={(url = projectData?.project_assets?.image[0]) => window.open(projectData?.project_assets?.image[0], '_blank', 'noreferrer')} />
                      </div>
                    ) :
                    (
                      <img
                        src={projectData?.project_assets?.image[0]}
                        alt={projectData?.project_name}
                        style={{ width: "100%", height: "100%" }}
                      />
                    )
                }
              </Grid>
            </Grid>
          </div>
          <div className={classes.cardGrid}>
            <Grid container spacing={4} p={2} >
              <div className={classes.card}>
                <Typography component="h5" variant="h5">
                  Project Goal
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{ textAlign: "justify" }}
                >
                  {projectData?.project_description}
                </Typography>
                <div style={{ padding: "15px" }} />
                <Typography component="h5" variant="h5">
                  NGO and Location
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{ textAlign: "justify" }}
                >
                  {projectData?.project_ngo_location}
                </Typography>
                <div style={{ padding: "15px" }} />
                <Typography component="h5" variant="h5">
                  Project Milestone
                </Typography>
                {milestoneComponents}
                <div style={{ padding: "15px" }} />
              </div>
            </Grid>
            <div style={{ padding: "15px" }} />
            {
              projectData?.project_assets?.youtube_video_id.map((video, index) => (
                video ? (
                  <Grid style={{ border: '1px solid black', padding: '20px', margin: '20px' }} container justifyContent={"center"} align="center" key={index}>
                    <Grid item xs={12}>
                      <Overview
                        url={`https://www.youtube.com/watch?v=${video}`}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  null)
              )
              )
            }
            <Grid container justifyContent={"center"} align="center">
              <Grid item xs={12}>
                {
                  projectData?.project_assets?.image.map((imagedata, index) => {
                    if (index !== 0) {
                      const imageSrc2 = imagedata.substring(imagedata.lastIndexOf('.')).split('?alt')[0];
                      if (imageSrc2 === '.pdf') {
                        return (
                          <div style={{ textAlign: "center" }}>
                            <img src={pdf_icon} style={{ height: '10%', width: '10%' }} color="primary" onClick={(url = imagedata) => window.open(imagedata, '_blank', 'noreferrer')} />
                          </div>
                        );
                      } else {
                        return (
                          <img
                            src={imagedata}
                            alt={projectData?.project_charity}
                            className={classes.image}
                            style={{ width: "100%", height: "100%" }}
                          />
                        );
                      }
                    }
                  })}
              </Grid>
            </Grid>
            <div className={classes.content}>
              <Grid container spacing={3}>
                <Grid item xs>
                  <Paper className={classes.contentpaper} elevation={0}>
                    ${projectData?.donated_amount?.toFixed(2)} of ${projectData?.project_funding_goal?.amount} raised
                  </Paper>
                </Grid>
                {
                  projectData?.project_status === ""? "" :(
                    <Grid item xs>
                      <Paper className={classes.contentpaper} elevation={0}>
                        <Button
                          style={{ background: "#0133C0", color: "white", marginTop: '-5px', textTransform: "capitalize" }}
                          className={classes.button}
                          to={{
                            pathname: "/login",
                            state: {
                              project_data: projectData,
                            },
                          }}
                          component={Link}
                        >
                          Donate
                        </Button>
                      </Paper>
                    </Grid>
                  )
                }
                <Grid item xs>
                  <Paper className={classes.contentpaper} elevation={0}>
                    <IconButton
                      aria-label="add to favorites"
                      // onClick={handleFavorite}
                      style={{
                        marginTop: '-7px',
                        color: isFavourite ? "red" : "light-grey"
                      }}
                    >
                      <FavoriteIcon />
                    </IconButton>
                  </Paper>
                </Grid>
              </Grid>
            </div>
            <div className={classes.end}>
              <Grid container>
                <Grid item>
                  <Typography style={{ fontWeight: "bold" }}>
                    {/* Who has contributed? */}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>

        </DetailsContainer>
      </Grid>
      <ShareDialog
        open={open}
        close={handleDone}
        formMode={formMode}
        URL={urlBase + 'details?id=' + projectId}
      />
    </>
  )
}
