import React from 'react';
import HeaderBar from '../../modules/views/HeaderBar'
import Footer from '../../modules/views/Footer';
// import Header from '../../modules/views/Header'
import Reality from '../../modules/views/Reality'
// import Problems from '../../modules/views/Problems'
import Device from '../../modules/views/Device'
import Solutions from '../../modules/views/Solutions';
import DiscorverProjects from '../../modules/views/DiscorverProjects';
import CaritasRevolutions from '../../modules/views/CaritasRevolutions';


function About() {
  return (
    <div style={{ backgroundColor: '#fff' }}>
      <HeaderBar />
      {/* <Header /> */}
      <Reality />
      {/* <Problems /> */}
      <Solutions />
      <CaritasRevolutions />
      <DiscorverProjects />
      <Device />
      <Footer />
    </div>
  );
}

export default About
