import React from "react";
import { Grid, Typography, Box, Paper } from "@mui/material";
import { Link } from "react-router-dom";

export default function CauseCard(props) {
  console.log(props.cause.project_count);
  const { classes } = props;

  return (
    <Paper className={classes.paper} key={props.index}>
      <Link
        to={{
          pathname: "/projects",
          state: {
            causeIndex: props.causeIndex,
          },
        }}
      >
        <Grid container spacing={3} alignitems="center" justifyContent={"center"}>
          <Grid item>
            <img
              src={props.cause.icon}
              alt={props.cause.cause_display_name}
              className={classes.imageComponent}
            />
          </Grid>
          <Grid item xs={10} lg={6} md={5} sm={10}>
            <Box style={{ textAlign: "center" }}>
              <Typography className={classes.typography1}>
                {props.cause.cause_display_name}
              </Typography>
              <Typography className={classes.count}>
                {props.cause.project_count}{" "}
                {props.cause.project_count > 1 ? "projects" : "project"}{" "}
              </Typography>
            </Box>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Link>
    </Paper>
  );
}
