import { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import { useNavigate } from 'react-router-dom';


const Logout = ({ logout }) => {
  const history = useNavigate();
  useEffect(() => {
    logout();
    history('/');

  }, [logout]);
  return null;
};


const mapDispatchToProps = {
  logout: actions.signOut,
};

export default connect(
  null,
  mapDispatchToProps
)(Logout);
