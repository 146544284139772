import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";

const styles = (theme) => ({
  root: {
    overflow: "hidden",
    [theme.breakpoints.only("xl")]: {
      marginTop: "45px",
    },
    [theme.breakpoints.only("xs")]: {
      marginTop: "40px",
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: "35px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      marginTop: "30px",
    },
  },
  images: {
    marginTop: theme.spacing(2),
    align: "center",
    [theme.breakpoints.down("xs")]: {
      align: "center",
    },
  },

  content: {
    marginTop: "-40px",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignitems: "center",
    padding: theme.spacing(0, 3),
  },
  p2: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  paper: {
    marginTop: "-80px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  about: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  container: {
    overflow: "hidden",
    backgroundColor: "#FFF",
    marginTop: "40px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "50px",
    },
  },
  text: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "justify",
    },
  },
  shapeColor: {
    backgroundColor: "#2057C0",
    borderRadius: "50px",
    borderTopWidth: 2,
    borderBottomWidth: 2,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderColor: "grey",
    borderStyle: "solid",
  },
});

function CaritasRevolutions(props) {
  const { classes } = props;

  return (
    <div
      className={`wow animate__slower animate__animated animate__fadeInLeft ${classes.root}`}
    >
      <Grid container justifyContent={"center"}>
        {/* <Grid
          item
          xs={10}
          sm={10}
          md={10}
          lg={10}
          xl={10}
          align="center"
          className={classes.shapeColor}
        >
          <br />
          <Typography
            align="center"
            style={{
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '40px',
              color: '#fff',
            }}
          >
            CARITAS REVOLUTION
          </Typography>

          <Typography
            align="center"
            style={{
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              color: '#fff',
            }}
          >
            Is an international team of experts from different cultures and
            backgrounds who have the same goal and passion: To support the many
            charities out there who truthfully are helping those in need daily.
          </Typography>

          <Typography
            align="center"
            style={{
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              color: '#fff',
            }}
          >
            Is committed to providing full financial transparency for every
            project we supervise as well as open books of our organization.
          </Typography>
          <br />

          <Typography
            align="center"
            style={{
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              color: '#fff',
            }}
          >
            Is not simply a platform where charities present their needs.
            Caritas Revolution is much more than that: It is an all-in-one
            solution for charities who might not have the know-how, financial
            resources, or capacity to get to the next level by their own
            strength.
          </Typography>
          <br />

          <Typography
            align="center"
            style={{
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              color: '#fff',
            }}
          >
            Promote the projects, raise the funds, create, and implement the
            right solutions - Completely free to the charities soliciting our
            services.
          </Typography>
          <br />
          <Typography
            align="center"
            style={{
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              color: '#fff',
            }}
          >
            Believes that the success of our grassroot program will help shift
            the focus on charities who guarantee a full financial transparency
            and accomplishment of their defined goals. This will motivate other
            humanitarian organizations to follow the same path which ultimately
            will benefit the most important group of people in this process: the
            ones who so desperately need our help.
          </Typography>
          <br />
        </Grid> */}
        <Grid
          item
          xs={10}
          sm={10}
          md={10}
          lg={10}
          xl={10}
          align="left"
          style={{
            padding: "2.5rem",
            paddingTop: "1.7rem",
            backgroundColor: "#fff",
            color: "#000",
            boxShadow: "rgb(206 206 206) 0px 0px 10px",
            borderRadius: "10px",
            fontFamily: "Poppins",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <br />
          <Typography
            variant="h2"
            style={{
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "32px",
            }}
          >
            WHAT WE DO / HOW IT WORKS
          </Typography>
          <Typography
            style={{
              fontStyle: "normal",
              fontSize: "16px",
              marginTop: "20px",
              fontFamily: "Poppins",
              color: "#000",
            }}
          >
            Caritas Revolution is so much more than simply a platform for
            charities: it is an all-in-one solution for charities who may lack
            the know-how, financial resources, and capacity to bring their
            projects to fruition. All projects are managed by our on-site local
            representatives. By overseeing these projects, we show you exactly
            how your generosity is being used to help those in need, down to the
            dollar. We are able to provide an exact breakdown of how your
            donation is invested, and the change you are helping bring to our
            world.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

CaritasRevolutions.propTypes = { classes: PropTypes.object.isRequired };

export default withStyles(styles)(CaritasRevolutions);
