import React from 'react';

function CryptoEmbed(props) {
  return (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    <iframe
      src="https://commerce.coinbase.com/charges/RXYPFDRT"
      style={{ width: '100%', height: 1000, border: 0, overflow: 'hidden' }}
    />
  );
}

export default CryptoEmbed;
