import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '../modules/styles/Typography';
import HeaderBar from '../modules/views/HeaderBar'
import ContactForm from './ContactForm'

function Contact() {
  return (
    <React.Fragment>
      <HeaderBar />
      <Container>
        <Box mt={10} mb={6} align="center">
          <Typography variant="h3" gutterBottom text="center">
            Contact Us
          </Typography>
        </Box>
        <ContactForm />
      </Container>
    </React.Fragment>
  );
}

export default Contact;