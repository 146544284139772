import React, { useState, useEffect } from 'react';
import { Box, Modal, Button } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import AddCard from '../../../pages/payment/Form/addCreditCardTemp';
import { myCollection } from '../../../Firebase/Firebase';


export default function AddCardTemp({
  _params,
  setCards,
  setIsPaymentMethodSelected,
}) {
  const [openCard, setOpenCard] = useState(false);
  
    const handleCloseCard = () => {
      setOpenCard(false);
    } 
      const [StripePublishedKey, setStripePublishedKey] = useState('');

  const fetchData = async () => {
    const snapshot = await myCollection.get();
    const docs = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setStripePublishedKey(docs[0]['STRIPE_Publishable_Key']);
    setOpenCard(true);
  };

  const stripePromise = loadStripe(StripePublishedKey);

  // firebase collection to get stripe key

  useEffect(() => {
    fetchData();
  }, []);

  const addCardWIthoutSaving = (card) => {
    setCards((cards) => [...cards, card]);
    setIsPaymentMethodSelected(true);
    _params['pm_token'] = card['id'];
  };

  return (
    <div>
      <div style={{ width: '100%' }}>
        <Button
          variant="outlined"
          color="primary"
          style={{
            textTransform: 'capitalize',
            fontSize: '18px',
            borderRadius: '5px',
          }}
          onClick={() => fetchData()}
        >
          Use New Card
        </Button>
      </div>

      <Modal
        // hideBackdrop
        open={openCard}
        onClose={handleCloseCard}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            bgcolor: 'background.paper',
            border: '2px solid #2057C0',
            boxShadow: 24,
            p: 1,
            justifyContent: 'center',
          }}
        >
          <Elements stripe={stripePromise}>
            <AddCard
              addCardWIthoutSaving={addCardWIthoutSaving}
              close={handleCloseCard}
            />
          </Elements>
        </Box>
      </Modal>
    </div>
  );
}
