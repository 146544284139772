import React from 'react'
import CausesList from '../causes/CausesList'

function My_Causes() {
    return(
        <React.Fragment>
            <CausesList />
        </React.Fragment>
    )
}

export default My_Causes;

