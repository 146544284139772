import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import { cloudFunctions, auth, database } from '../../../../Firebase/Firebase';
import SimpleBackdrop from './SimpleBackdrop';
import { Box, Modal } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export default function DialogCryptoNoProject({
  openBackdrop,
  donateWithCrypto,
  totalDonation,
}) {
  const [setstripeFees] = useState([]);
  const [openCard, setOpenCard] = useState(false);
  const [alertStatus, setAlertStatus] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const handleOpenCard = () => setOpenCard(true);
  const handleCloseCard = () => setOpenCard(false);

  useEffect(() => {
    getStripeFees();
    getDataList();
    let generate_id = Math.floor(Math.random() * 100000000000);
    localStorage.setItem('unique', generate_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const BasicAlerts = () => {
    return (
      <Modal
        open={openCard}
        onClose={handleCloseCard}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            // bgcolor: 'background.paper',
            // border: '2px solid #2057C0',
            boxShadow: 24,
            p: 1,
            justifyContent: 'center',
          }}
        >
          <Alert severity={alertStatus}>
            <AlertTitle>{alertStatus}</AlertTitle>
            {alertMessage}
          </Alert>
        </Box>
      </Modal>
    );
  };

  const getDataList = () => {
    database.ref('crypto_transactions/').on('value', function (snapshot) {
      snapshot.forEach((doc) => {
        if (
          doc.val().unique_id === localStorage.getItem('unique') &&
          doc.val().user_name === auth().currentUser.displayName
        ) {
          setAlertStatus('success');
          setAlertMessage('Donation made with success!');
          handleOpenCard();
          // history.push({
          //   pathname: '/thanks',
          //   state: {
          //     project_description: props.projectDescription,
          //     total_amount_donated: parseFloat(props.donate_amount) + parseFloat(doc.val().amount),
          //     total_fund_needed: props.totalAmount
          //   }
          // })
        }
      });
    });
  };

  const getStripeFees = (event) => {
    const stripeFees = cloudFunctions.httpsCallable('getStripeChargeInfo');

    stripeFees()
      .then((result) => {
        setstripeFees(result.data);
      })
      .catch((error) => {
        console.log(`error: ${JSON.stringify(error)}`);
      });
  };

  return (
    <>
      {openBackdrop && <SimpleBackdrop open={openBackdrop} />}
      <Grid
        container
        justifyContent="space-around"
        style={{ padding: '30px 40px 10px 40px' }}
      >
        <Grid item>
          <Button
            style={{
              textTransform: 'capitalize',
              variant: 'contained',
              backgroundColor: '#0133C0',
              borderRadius: '5px',
              color: '#fff',
              width: '250px',
              fontSize: '18px',
              marginLeft: '10px',
            }}
            onClick={() => donateWithCrypto()}
          >
            Donate {'$' + totalDonation}
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignitems="center"
      >
        <BasicAlerts />
      </Grid>
    </>
  );
}
