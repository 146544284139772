import React from "react";
import Paper from "@mui/material/Paper/Paper";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid/Grid";
import banner from "./Main-banner.png";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    flexGrow: 1,
    marginTop: "100px",
    // height: "75vh",
    backgroundColor: "#FFF",
  },
  menuButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  header: {
    [theme.breakpoints.only("md")]: {
      // display: "none",
      paddingTop: "70px",
    },
    [theme.breakpoints.only("sm")]: {
      // display: "none",
      paddingTop: "70px",
    },
    [theme.breakpoints.only("xs")]: {
      // display: "none",
      paddingTop: "40px",
    },
  },
  media: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
      width: "420px",
      height: "220px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
      width: "320px",
      height: "150px",
    },
    width: "auto",
    height: "520px",
    marginLeft: "8%"

  },
  typography: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "13px",
      marginTop: "89px",
      fontSize: "43px",
      color: "#2057C0",
      fontWeight: "bold",
      // padding: "5px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "13px",
      marginTop: "10px",
      fontSize: "43px",
      color: "#2057C0",
      fontWeight: "bold",
      // padding: "5px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "13px",
      marginTop: "10px",
      marginBottom: "2px",
      fontSize: "20px",
      color: "#2057C0",
      fontWeight: "bold",
      // paddingTop: "5px",
      // paddingBottom: "2px",
    },
  },
  typoCharity: {
    [theme.breakpoints.up("md")]: {
      marginTop: "95px",
      color: "#2057C0",
      fontSize: "30px",
      padding: "10px",
      marginLeft: "10px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "95px",
      color: "#2057C0",
      fontSize: "20px",
      padding: "10px",
      marginLeft: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "1px",
      marginBottom: "2px",
      color: "#2057C0",
      fontSize: "20px",
      padding: "10px",
      marginLeft: "10px",
    },
    fontSize: "30px",
    color: "#2057C0",
  },
  box: {
    align: "center",
    // marginTop: "10px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "25%",
      width: "600px",
    },
    [theme.breakpoints.down("md")]: {
      align: "center",
      width: "300px",
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/* <Grid>
        <CardMedia
          className={classes.media}
          image={banner}
        />
      </Grid> */}

      <Grid>
        <img src={banner} width='100%' />
      </Grid>
      
      <Grid className="ban-sec" container spacing={2}>
        <Paper
          elevation={0}
          style={{ background: "transparent" }}
          className={classes.header}
        >
          {/* <Grid container alignItems="center" justify="center" style={{ height: '100%' }}>
            <Grid item>
              <IconButton
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                disableRipple
                disabled
              >
                <CardMedia
                  className={classes.media}
                  image={banner}
                />

              </IconButton>
            </Grid>

          </Grid> */}
        </Paper>
        {/* <Grid item xs={7} justifyContent="center">
          <Grid align="center">
            <Title />
          </Grid>
        </Grid> */}
      </Grid>


    </div >
  );
};
export default Header;

