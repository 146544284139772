import React from 'react'
import Slider from './slider/Slider'

function HomeContent() {
  return (
    <React.Fragment>
      <Slider />
    </React.Fragment>
  )
}

export default HomeContent;