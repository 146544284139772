import React from 'react';
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function InsetDividers(props) {
  const classes = useStyles();
  const { contributedUser } = props

  return (
    <List className={classes.root}>
      <ListItem key={props.index} >
        <ListItemAvatar>
          <Avatar alt="User" src={props.userIamge} className={classes.avatar}>
            {/* {props.contributedUser.charAt(0)} */}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={contributedUser}
        />
      </ListItem>

    </List>
  );
}