import React, { useState, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Grid, Button } from '@mui/material';
import { cloudFunctions } from '../../../../Firebase/Firebase';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import SimpleBackdrop from './SimpleBackdrop';
import BasicAlerts from '../../../../modules/views/components/BasicAlerts';
import ReCAPTCHA from 'react-google-recaptcha';


export default function DialogStripeNoProject({
  open,
  openCard,
  handleOpenCard,
  handleCloseCard,
  getTotalDonationWithStripeFees,
  selectedAmount,
  donate,
  params,
  totalDonation,
  alertStatus,
  alertMessage,
  captchaToken,
}) {
  const [setstripeFees] = useState([]);

  useEffect(() => {
    getStripeFees();
  },[]);



  const getStripeFees = (event) => {
    const stripeFees = cloudFunctions.httpsCallable('getStripeChargeInfo');

    stripeFees()
      .then((result) => {
        // console.log(`result: ${JSON.stringify(result)}`);
        setstripeFees(result.data);
      })
      .catch((error) => {
        console.log(`error: ${JSON.stringify(error)}`);
      });
  };

  return open ? (
    <SimpleBackdrop open={open} />
  ) : (
    <>
      <FormControl component="fieldset">
        <RadioGroup
          defaultValue="no"
          aria-label="choice"
          onChange={(e) => {
            let isPaying = e.target.value === 'yes' ? true : false;
            getTotalDonationWithStripeFees(isPaying, selectedAmount);
          }}
        >
          <FormControlLabel
            value="yes"
            control={<Radio color="primary" />}
            label="Sure, I'll take care of it."
          />
          <FormControlLabel
            value="no"
            control={<Radio color="primary" />}
            label="No, not at this time."
          />
        </RadioGroup>
      </FormControl>
      <ReCAPTCHA
        sitekey={"6LcYBD4oAAAAAIPrHb-GOwtbsMg8Ene1gwL8t2Xy"}
        onChange={(token) => captchaToken.push(token)}
      />
      <Grid
        container
        justifyContent="space-around"
        style={{ padding: '30px 40px 10px 40px' }}
      >
        <Grid item>
          <Button
            style={{
              textTransform: 'capitalize',
              variant: 'contained',
              backgroundColor: '#0133C0',
              borderRadius: '5px',
              color: '#fff',
              width: '250px',
              fontSize: '18px',
              marginRight: '10px',
            }}
            onClick={() => donate(params)}
          >
            Donate {'$' + totalDonation}
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignitems="center"
      >
        <Grid item xs={12}></Grid>
        <BasicAlerts
          alertStatus={alertStatus}
          alertMessage={alertMessage}
          openCard={openCard}
          handleCloseCard={handleCloseCard}
          handleOpenCard={handleOpenCard}
        />
      </Grid>
    </>
  );
}
